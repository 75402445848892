@import '../colors.scss';

.addServiceData svg {
  font-size: 24px;
}

// Account Dashboard

.dashboard {
  .profile-card__body {
    display: -moz-box;
    display: flex;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: center;
    justify-content: center;
    .profile-card__avatar {
      margin-bottom: 16px;
      width: 150px;
      height: 150px;
      img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .profile-card__name {
      font-weight: 500;
      line-height: 20px;
    }
    .profile-card__email {
      font-size: 15px;
      margin-bottom: 24px;
    }
  }
  .address-card {
    position: relative;
    .address-card__body {
      padding: 2rem;
      .address-card__name {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 1.125rem;
      }
      .address-card__row {
        .address-card__row-title {
          font-size: 13px;
          color: #6c757d;
        }
        margin-top: .75rem;
      }
    }
    .address-card__footer {
      margin-top: 1.625rem;
    }
  }
}

.medCartModal {
  top: 30px;
  width: 70% !important;
}

// .account-prices {
//   background-color: $blue;
//   a {
//     color: white !important;
//   }
// }

.current-storage-profile{
  .ant-card-head {
    display: none;
  }
  .ant-card-head-title {
    font-size: 30px;
    padding: 6px 0;
  }
  .ant-card-head-title, .size {
    text-align: center;
  }
  .size {
    font-size: 25px;
  }
  .ant-card-body {
    text-align: center;
  }
}

// Account Nav

.account-nav {
  .active {
    color: black !important;
    svg {
        color: black !important;
    }
  }
  width: 100%;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  .account-nav__title {
    padding: 1.375rem 1.5rem;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0;
  }
  .account-nav__list {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 15px;
    line-height: 20px;
    .account-nav__item a, .account-nav__item button {
      cursor: pointer;
      display: block;
      color: #6c757d;
      padding: 7px 1.5rem;
      border: none;
      width: 100%;
      background: transparent;
      text-align: start;
      font-weight: 600;
    }
  }
}

@media screen and (min-width: 992px){
  .account-nav__list {
    padding-bottom: 1.375rem;
  }
}

.account-nav__divider {
  height: 1px;
  background: #ebebeb;
  margin: 0 0 0 10px;
}

// Order History 

.card-table {
  font-size: 15px;
  table {
    width: 100%;
    min-width: 500px;
    thead {
      th:first-child {
        padding-left: 2rem;
      }
      th {
        border-bottom: 1px solid #ebebeb;
        text-transform: uppercase;
        font-size: 13px;
        padding: 10px 12px;
      }
    }
  }
}

.card-table tbody+tbody tr td, .card-table tbody+tbody tr th, .card-table tbody+tfoot tr td, .card-table tbody+tfoot tr th, .card-table tbody tr+tr td, .card-table tbody tr+tr th {
  border-top: 1px solid #ebebeb;
}

.card-table tbody td, .card-table tbody th, .card-table tfoot td, .card-table tfoot th {
  padding: 12px;
}

.card-table td:first-child, .card-table th:first-child {
  padding-left: 2rem;
}
