
.hospitalCard {
  padding: 10px 25px 10px 10px;
  z-index: 4;
  .hospital-img {
    flex: 35%;
    img {
      min-width: 40px;
      width: 100%;
    }
  }

  .hos-content {
    margin-left: 10px;
    background: #fff;
    border-radius: 0 3px 3px 0;
    h3 {
      font-size: 16px;
      padding-bottom: 8px;
      font-weight: 600;
      color: #0071c2;
      text-decoration: none;
      cursor: pointer;
    }
    flex: 65%;
    .hospitalMark {
      display: flex;
      margin-bottom: 10px;
      .hos-level {
        margin-right: 8px;
        display: flex;
        justify-content: center;
        vertical-align: baseline;
        border-radius: 6px 6px 6px 0;
        height: 32px;
        width: 32px;
        min-width: 32px;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        background: #003580;
        color: #fff;
      }
      p {
        color: #003580;
        font-size: 16px;
        font-weight: 500;
        line-height: 1 !important;
      }
      span {
        color: #6b6b6b;
        font-size: 12px;
        font-weight: 500;
        line-height: 1 !important;
        margin-top: 4px;
      }
    } 
    a {
      font-size: 13px;
      font-weight: normal;
      line-height: none;
    }
  }
}

@media screen and (max-width: 576px) {
  .hos-content {
    h3 {
      font-size: 12px !important;
    }
    a {
      display: none !important;
    }
  }
  .hos-level {
    border-radius: 4px 4px 4px 0 !important;
    height: 28px !important;
    width: 28px !important;
    min-width: 28px !important;
    font-size: 14px !important;
  }
}