.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.compare-table {
  width: 100%;
  font-size: 15px;
  border-spacing: 0;
  tr:not(:last-child) {
    border-bottom: 1px solid #ebebeb;
  }
}

table {
  border-collapse: collapse;
}

.compare-table__column--header {
  text-align: right;
  width: 15%;
  background: #f7f7f7;
  font-weight: 500;
  min-width: 140px;
}

.compare-table__column--header, .compare-table__column--product {
  padding: 14px 16px;
}

.compare-table__column--fake, .compare-table__column--product {
    border-left: 1px solid #ebebeb;
}

.compare-table__column--product {
    width: 17%;
    text-align: center;
    vertical-align: top;
    min-width: 180px;
}

.compare-table__product {
    display: block;
    line-height: 20px;
    color: inherit;
    -webkit-transition: color .12s;
    transition: color .12s;
}

.compare-table__product-image {
    width: 150px;
    margin: 0 auto;
}

.compare-table__product-name {
    color: inherit;
}

.image--type--category .image__body, .image--type--product .image__body {
    padding-bottom: 100%;
}

.image--type--category .image__body, .image--type--product .image__body {
    display: block;
    position: relative;
    width: 100%;
}

.compare__option, .compare__options-list {
    display: flex;
    align-items: center;
}

.compare__options-list {
    padding: 16px 20px;
    border-bottom: 1px solid #ebebeb;
    font-size: 15px;
    justify-content: flex-end;
}

.compare__option+.compare__option {
    margin-left: 16px;
}

.compare__option-label {
    margin-right: 8px;
}

.button-toggle__list {
    display: flex;
}

.button-toggle__item {
    margin: 0;
}

.button-toggle__input {
    position: absolute;
    clip: rect(0,0,0,0);
    pointer-events: none;
}

.button-toggle__item:first-child .button-toggle__button {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}

.button-toggle__input:checked~.button-toggle__button {
    background: #e52727;
    color: #fff;
}

.button-toggle__button {
    margin: 0;
    background: #f0f0f0;
    color: #333;
    font-weight: 500;
    font-size: 13px;
    line-height: 14px;
    padding: 5px 9px;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: background .12s,color .12s;
    transition: background .12s,color .12s;
}

.button-toggle__item:last-child .button-toggle__button, .button-toggle__item:first-child .button-toggle__button {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}

.button-toggle__input:checked~.button-toggle__button {
    background: #e52727;
    color: #fff;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.btn-xs:not(.btn-icon) {
    font-size: 13px;
    line-height: 15px;
    padding: calc(9px / 2) 9px;
}

.btn {
    border: none;
    border-radius: 2px;
    fill: currentColor;
    font-weight: 500;
}