.newsItem {
  background-color: #fff;
  img {
    max-width: 100%;
    height: 200px;
    object-fit: cover;
    width: 100%;
  }
  .newsItem__content {
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.1);
    position: relative;
    flex-direction: column;
    padding: 1.125rem 1.25rem 1rem;
    display: flex;
    .newsItem__category {
      background-color: white;
      position: absolute;
      left: 0;
      bottom: calc(100% - 1px);
      font-weight: 500;
      z-index: 0;
      height: 22px;
      padding: .75rem 1.25rem 0;
      font-size: 13px;
      &:before {
        position: absolute;
        display: block;
        content: "";
        top: 0;
        background: #fff;
        z-index: -1;
        height: 100%;
        left: 0;
        border-top-right-radius: 2px;
        transform: skewX(30deg);
        transform-origin: left top;
        width: 100%;
      }
    }
    .newsItem__title {
      h2 {
        font-size: 15px;
        line-height: 20px;
        overflow: hidden;
        max-height: 40px;
        a {
          color: black;
          text-decoration: none;
          background-color: transparent;
          &:hover {
            text-decoration: underline !important;
          }
        }
      }
    }
    .newsItem__date {
      order: 1;
      font-size: 13px;
      a {
        font-size: 13px;
      }
      &:before {
        // display: block;
        content: "";
        height: 1px;
        background: currentColor;
        opacity: .6;
        position: relative;
        display: inline-block;
        width: 20px;
        top: -1px;
        margin-right: 2px;
        vertical-align: middle;
      }
    }
  }

}