.checkoutHistory {
  background-color: #FAFAFA;
  padding-bottom: 50px;
}

.order-success__body {
    max-width: 900px;
    margin: 0 auto;
}

.order-success__header {
    padding: 60px 0 52px;
    text-align: center;
}

.order-success__icon {
    fill: #e52727;
    margin: 0 auto;
    display: block;
}

.order-success__title {
    margin-top: 24px;
    margin-bottom: 2px;
}

.order-success__subtitle {
    font-size: 19px;
    font-weight: 500;
}

.order-success__actions {
    margin-top: 20px;
}

.order-success__meta {
    padding: 16px 0;
    margin-bottom: 20px;
    text-align: center;
}

.order-success__meta-item, .order-success__meta-list {
    display: -moz-box;
    display: flex;
    -moz-box-pack: center;
    justify-content: center;
}

.order-success__meta-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.order-success__meta-item {
    flex-basis: 25%;
    line-height: 1.25;
    position: relative;
    padding: 2px 12px;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
}

.order-success__meta-item:not(:last-child):before {
    right: -1px;
}

.order-success__meta-item:not(:last-child):before {
    position: absolute;
    display: block;
    content: "";
    border-left: 2px dashed rgba(0,0,0,.1);
    width: 0;
    height: 100%;
    top: 0;
}

.order-success__meta-title {
    display: block;
    font-size: 13px;
    color: #6c757d;
    margin-bottom: 4px;
}

.order-success__meta-value {
    display: block;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
}

.order-list table {
    width: 100%;
    font-size: 15px;
}

.order-list__header td, .order-list__header th {
    text-transform: uppercase;
    color: #6c757d;
    font-size: 13px;
    font-weight: 500;
}

.order-list__column-quantity {
    text-align: center;
}

.order-list__column-total {
    text-align: right;
}

.order-list__column-image {
    width: 1px;
}

.order-list__column-image .image {
    width: 40px;
}

.image--type--category .image__body, .image--type--product .image__body {
    padding-bottom: 100%;
    display: block;
    position: relative;
    width: 100%;
}

.order-list a {
    color: inherit;
}

.image--type--category .image__tag, .image--type--product .image__tag {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}

.order-list__footer {
    font-size: 20px;
}

th {
    text-align: inherit;
}

.order-list__footer th {
    font-weight: 500;
}

@media (min-width: 576px){
  .order-list td:first-child, .order-list th:first-child {
      padding-left: 1.5rem !important;
  }
  .order-list td:last-child, .order-list th:last-child {
    padding-right: 1.5rem !important;
  }
  .order-list__header td, .order-list__header th {
    border-bottom: 1px solid #ebebeb;
    padding: 10px 12px !important;
  }
  .order-list td, .order-list th {
    padding: 4px 12px;
  }
  .order-list__products tr:first-child td, .order-list__products tr:first-child th {
    padding-top: 14px;
  }
  .order-list__products td, .order-list__products th {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .order-list__subtotals td, .order-list__subtotals th {
    padding-top: 10px;
    padding-bottom: 3px;
  }
  .order-list__subtotals tr:first-child td, .order-list__subtotals tr:first-child th {
    padding-top: 12px;
    border-top: 1px solid #ebebeb;
  }
  .order-list__footer td, .order-list__footer th {
    padding-top: 14px;
    padding-bottom: 14px;
    border-top: 1px solid #ebebeb;
  }
  .order-list__subtotals tr:last-child td, .order-list__subtotals tr:last-child th {
    padding-bottom: 12px;
  }
}

@media (max-width: 767.98px) {
  .order-success__meta-list {
    flex-wrap: wrap;
  }
  .order-success__meta-item {
    flex-basis: 50%;
  }
  .order-success__meta-item:nth-child(n+3) {
    margin-top: 16px;
  }
  .order-success__meta-item:nth-child(2)::before, .order-success__meta-item:nth-child(4)::before {
    border-left: 0px;
  }
}

@media (max-width: 575.98px) {
  .order-list__products tr:not(:first-child) {
    border-top: 1px solid #ebebeb;
  }
  .order-list__footer, .order-list__header, .order-list__products, .order-list__subtotals {
    display: block;
  }
  .order-list table, .order-list td, .order-list th, .order-list tr {
    display: block;
    padding: 0;
  }
  .order-list__footer tr, .order-list__header tr, .order-list__subtotals tr {
    display: -moz-box;
    display: flex;
    justify-content: space-between;
  }
  .order-list__header tr {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    border-bottom: 1px solid #ebebeb;
  }
  .order-list__products .order-list__column-product {
    display: -moz-box;
    display: flex;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
    -moz-box-pack: center;
    justify-content: center;
    width: calc(100% - 40px);
  }
  .order-list__products .order-list__column-quantity, .order-list__products .order-list__column-total {
    margin-top: .625rem;
    margin-bottom: -.125rem;
  }
  .order-list__products .order-list__column-product {
    padding-left: 60px;
  }
  .order-list__products tr {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
    display: -moz-box;
    display: flex;
  }
  .order-list__footer tr, .order-list__header tr, .order-list__products tr, .order-list__subtotals tr {
    padding: 0 1rem;
  }
  .order-list__footer {
    padding-top: 12px;
    padding-bottom: 12px;
    border-top: 1px solid #ebebeb;
  }
  .order-list__footer tr, .order-list__header tr, .order-list__subtotals tr {
    display: -moz-box;
    display: flex;
  }
}