@import '../../../colors.scss';

#header {
  background: #fff;

  .header {
    background: #fff;
    padding: 15px 0;

    .open-button {
      transition: all 0.5s;
    }

    .close-button,
    .open-button {
      cursor: pointer;
      justify-content: center;
      align-items: center;

      svg {
        width: 25px;
        height: 25px;
      }
    }

    .close-button {
      svg {
        fill: red;
      }
    }

    .tel-icon {
      svg {
        fill: green;
      }
    }

    .open-button {
      svg {
        fill: black;
      }
    }

    .header-left {
      color: #262626;
      z-index: 10;

      .menusItem {

        &>a,
        &>button {
          font-weight: 500;
          display: block;
          border-radius: 2px;
          color: #04333b;
          white-space: nowrap;
          font-size: 17px;
          background-color: transparent;
        }

        &>a {
          margin-right: 15px;
        }
      }
    }

    .header-right {
      z-index: 10;

      p {
        font-size: 15px;
      }

      font-size: 24px;
      font-family: 'Arial',
      sans-serif;
    }
  }
}

.indicator {
  position: relative;

  .indicator__button {
    padding: 10px;
    display: -moz-box;
    display: flex;
    position: relative;
    min-width: 54px;
    color: inherit;
    border-radius: 2px;
    background: transparent;
    border: none;

    &:hover,
    &:focus {
      background: inherit;
      background-color: #f2f2f2;
    }

    .indicator__icon {
      padding: 2px;
      display: block;
      position: relative;

      .like-icon {
        path {
          fill: $blue;
        }
      }

      .compare-icon {
        color: $blue;
      }

      svg {
        color: #262626;
        width: 32px;
        height: 32px;

        path {
          width: 32px;
          height: 32px;
        }
      }

      .indicator__counter {
        position: absolute;
        top: -2px;
        right: -3px;
        font-size: 10px;
        line-height: 1;
        padding: 2px 3px 1px;
        border-radius: 6.5px;
        text-align: center;
        z-index: 0;
        color: #fff;
        font-weight: 500;

        &:before {
          left: 0;
          right: 0;
          transform: skewX(-11deg);
          background-color: $blue;
          display: block;
          position: absolute;
          content: "";
          top: 0;
          bottom: 0;
          z-index: -1;
          border-radius: 2.5px;
        }
      }
    }

    .indicator-text {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      align-content: space-between;
      justify-content: space-between;

      .indicator__title {
        display: block;
        white-space: nowrap;
        color: #999;
        padding-bottom: 3px;
        font-size: 13px;
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .indicator__value {
        font-weight: 500;
        font-size: 18px;
        display: block;
        white-space: nowrap;
      }
    }
  }

  .indicator__content {
    pointer-events: none;
    right: 0;
    top: 100%;
    position: absolute;
    visibility: hidden;
    transform-origin: top;
    transform: rotateX(60deg);
    opacity: 0;
    transition: transform .2s, opacity .2s, visibility 0s .2s;

    .dropcart {
      display: block;
      width: 320px;
      background-color: #fff;
      box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
      border-radius: 1.5px;
      padding: 24px;
      color: #262626;

      .dropcart__list {
        list-style: none;
        padding: 0;
        margin: -8px 0 0;

        .dropcart__item {
          display: -moz-box;
          display: flex;

          .dropcart__item-image {
            flex-shrink: 0;
            width: 70px;

            .image__body {
              padding-bottom: 100%;
              display: block;
              position: relative;
              width: 100%;

              .image__tag {
                display: block;
                position: absolute;
                width: 100%;
                height: 60px;
                object-fit: cover;
              }
            }
          }

          .dropcart__item-info {
            padding: 4px 0 5px;
            -moz-box-flex: 1;
            flex-grow: 1;
            margin: 0 10px;

            .dropcart__item-name {
              font-size: 15px;
              line-height: 18px;

              a {
                color: inherit;
                transition: color .2s;
              }
            }

            .dropcart__item-meta {
              display: -moz-box;
              display: flex;
              -moz-box-align: center;
              align-items: center;
              margin-top: 4px;

              .dropcart__item-quantity {
                padding-left: 6px;
                margin-right: -2.82352px;
                color: #fff;
                font-weight: 500;
                position: relative;
                height: 16px;
                z-index: 0;
                font-size: 11px;
                padding: 1px 10.82352px 0;

                &:before {
                  position: absolute;
                  left: 0;
                  background: $blue;
                  transform: skewX(-20deg);
                  transform-origin: left bottom;
                  display: block;
                  content: "";
                  width: calc(100% - 5.82352px);
                  height: 100%;
                  top: 0;
                  z-index: -1;
                  border-radius: 2.5px 3px;
                }
              }

              .dropcart__item-price {
                color: #262626;
                font-weight: 500;
                position: relative;
                height: 16px;
                z-index: 0;
                font-size: 11px;
                padding: 3px 10.82352px 0;

                &:before {
                  left: 0;
                  background: $blue;
                  transform: skewX(-20deg);
                  position: absolute;
                  display: block;
                  content: "";
                  width: calc(100% - 5.82352px);
                  height: 100%;
                  top: 0;
                  z-index: -1;
                  border-radius: 2.5px 3px;
                  background: #ebebeb;
                }
              }
            }
          }

          .dropcart__item-remove {
            margin-right: -13px;
            border-radius: 13px;
            display: -moz-box;
            display: flex;
            -moz-box-align: center;
            align-items: center;
            -moz-box-pack: center;
            justify-content: center;
            padding: 0;
            border: none;
            fill: currentColor;
            background-color: #fff;
            color: #ccc;
            position: relative;
            flex-shrink: 0;
            width: 26px;
            height: 26px;

            &:hover {
              background-color: #f2f2f2;
              color: #999;
            }

            svg {
              display: block;
              overflow: hidden;
            }
          }
        }

        .dropcart__divider {
          height: 1px;
          background: #ebebeb;
          margin: 8px 0;
        }
      }

      .dropcart__actions {
        display: -moz-box;
        display: flex;

        .btn-secondary {
          border-color: #f0f0f0;
          background: #f0f0f0;
          color: #333;
          flex-grow: 1;
        }

        .btn-primary {
          border-color: $blue;
          background: $blue;
          color: #fff;
          flex-grow: 1;
          margin-left: 8px;
        }
      }

      .dropcart__empty {
        text-align: center;
        font-size: 15px;
        padding: 20px 0;
      }
    }

    .account-menu {
      display: block;
      width: 280px;
      background-color: #fff;
      box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
      border-radius: 1.5px;
      color: #262626;

      .account-menu__form {
        padding: 0 30px 32px;

        .account-menu__form-title {
          text-align: center;
          padding: 32px 0 26px;
          font-weight: 500;
        }

        .form-group {
          margin-bottom: 1rem;

          .sr-only {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
          }

          .account-menu__form-forgot {
            position: relative;
          }

          .form-control-sm {
            height: 30px;
            padding: 5.5px 8px;
            font-size: 14px;
            line-height: 17px;
          }

          .form-control {
            border-radius: 2px;
            background-clip: border-box;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, background .15s ease-in-out, opacity .15s ease-in-out;
            color: #262626;
            background-color: #ebebeb;
            border: 1px solid #ebebeb;
            display: block;
            width: 100%;
          }

          .account-menu__form-forgot-link {
            right: 5px;
            position: absolute;
            top: 5px;
            bottom: 5px;
            border-radius: 2px;
            font-size: 12px;
            background: fff;
            color: #6c757d;
            display: -moz-box;
            display: flex;
            -moz-box-align: center;
            align-items: center;
            padding: 0 7px;
            transition: background .1s, color .1s;

            &:hover {
              background: #333;
              color: #fff;
            }
          }
        }

        .account-menu__form-button {
          margin-top: 32px;
          text-align: center;

          .btn {
            padding-left: 28px;
            padding-right: 28px;

            &:hover {
              background: #333;
              color: #fff;
            }
          }

          .btn-primary {
            border-color: $blue;
            background: $blue;
            color: #fff;
          }
        }

        .account-menu__form-link {
          font-size: 14px;
          text-align: center;

          a {
            color: #6c757d;
            transition: color .1s;

            &:hover {
              color: inherit;
            }
          }
        }
      }

      .account-menu__user {
        display: -moz-box;
        display: flex;
        -moz-box-align: center;
        align-items: center;
        padding: 14px 20px;
        color: inherit;

        .account-menu__user-avatar {
          margin-right: 14px;
          flex-shrink: 0;

          img {
            object-fit: cover;
            width: 45px;
            height: 45px;
            border-radius: 100%;
            max-width: 100%;
          }
        }

        .account-menu__user-info {
          display: -moz-box;
          display: flex;
          -moz-box-orient: vertical;
          -moz-box-direction: normal;
          flex-direction: column;
          -moz-box-pack: center;
          justify-content: center;

          .account-menu__user-name {
            font-size: 15px;
            line-height: 20px;
          }

          .account-menu__user-email {
            font-size: 14px;
            line-height: 18px;
            color: #6c757d;
            margin-top: 1px;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 180px;
            height: 1.2em;
            white-space: nowrap;
          }
        }
      }

      .account-menu__divider {
        height: 1px;
        background: #ebebeb;
      }

      .account-menu__links {
        list-style: none;
        padding: 12px 0;
        margin: 0;

        .active {
          color: black !important;

          svg {
            color: black !important;
          }
        }

        a,
        button {
          display: block;
          color: #6c757d;
          font-size: 15px;
          font-family: inherit;
          line-height: inherit;
          padding: 7px 20px;
          border: none;
          width: 100%;
          background: transparent;
          font-weight: 600;
        }

        button {
          text-align: start;
        }
      }
    }
  }
}

.indicator--open {
  .indicator__content {
    pointer-events: auto;
    z-index: 3;
    transition-delay: 0s, 0s, 0s;
    opacity: 1;
    visibility: visible;
    transform: rotateX(0deg);
  }
}

.common-search-box {
  position: static !important;

  .indicator__content {
    width: 100%;
  }
}

.citiesMenus {
  position: relative;

  .cityDisticts {
    position: absolute;
    background-color: white;
    left: 200px;
    display: none;
    top: 0;
    border: 1px solid black;
  }
}


.uz-map {
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-left: 10px;
  border: 1px solid black !important;
  padding: 5px !important;

  svg {
    width: 25px;
    height: 25px;
  }
}

.background-colors {
  background: linear-gradient(to right, $blue 50%, #333 50%),
}

.header-logo {
  flex: 20%;
  max-width: 20%;

  span,
  .logo {
    font-size: 30px;
    font-weight: 800;

    &:first-child {
      color: $blue;
    }

    &:last-child {
      color: #404040;
    }
  }
}

@media screen and (min-width: 1200px) {
  #header {
    .header {

      .header-left,
      .header-right {
        flex: 40%;
        max-width: 40%;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  #header {
    .header {
      box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 10%);
      height: 60px;
      padding: 0 15px;
      position: fixed;
      z-index: 100;
      top: 0;
      right: 0;
      left: 0;
      padding-top: 12px;
      padding-bottom: 12px;

      .header-right {
        .indicator {
          .indicator__button {
            padding: 0;
            min-width: 25px;
            margin-left: 10px;

            .indicator__icon {
              .indicator__counter {
                position: absolute;
                top: 0px;
                right: -5px;
                font-size: 8px;
              }

              svg {
                color: #262626;
                width: 25px;
                height: 25px;

                path {
                  width: 25px;
                  height: 25px;
                }
              }
            }
          }
        }
      }

      .header-logo {
        flex: 1;
        display: flex;
        justify-content: center;
      }
    }
  }
}

.menusItem {
  display: flex;
  justify-content: center;
  align-items: center;

  .active {
    color: $blue !important;
  }
}

.citiesMenus {
  width: 200px;
  list-style-type: none;
  background-color: #fff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);

  a {
    padding: 10px;
    display: block;
    color: black;
    font-weight: 500;
  }
}

.cityDisticts {
  list-style-type: none;
  background-color: #fff;

  a {
    padding: 10px;
    display: block;
    color: black;
    font-weight: 500;
  }
}

.headerMenus {
  .indicator__content {
    left: 0;
  }
}

@media screen and (max-width: 576px) {

  .close-button,
  .open-button {
    svg {
      width: 27px !important;
      height: 27px !important;
    }
  }

  .header-logo {
    span {
      font-size: 30px !important;
    }
  }
}

@media screen and (max-width: 500px) {
  #header {
    .header {
      height: 70px;
      display: flex;
      align-items: center;

      &>div {
        flex: 1;

        .close-button svg,
        .open-button svg {
          width: 30px !important;
          height: 35px !important;
        }

        .indicator__icon {
          svg {
            color: #262626;
            width: 30px !important;
            height: 30px !important;

            path {
              width: 30px !important;
              height: 30px !important;
            }
          }

          .indicator__counter {
            padding: 2px 4px 2px;
            font-size: 10px !important;
          }
        }
      }

      .header-logo {
        flex: 1 1;
        justify-content: center;

        span {
          font-size: 35px !important;
        }
      }

      .header-right .indicator .indicator__button {
        margin-left: 5px;
      }
    }
  }

  .filter__img {
    h4 {
      display: none;
    }
  }
}