.block-banners__list {
  display: -moz-box;
  display: flex;
  flex-wrap: wrap;
  margin: -15px;
}

.block-banners__item, .block-banners__item:hover {
  color: #fff;
}

.block-banners__item {
  margin: 15px;
  width: calc(50% - 30px);
  height: 210px;
  padding: 32px 34px;
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  z-index: 0;
  &:hover {
    color: #fff;
  }
  &:before {
    background: linear-gradient(90deg,rgba(26,26,26,.98) 25%,rgba(26,26,26,0) 90%);
    border-radius: 3px;
    z-index: -1;
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.block-banners__item:hover .block-banners__item-image {
  transform: scale(1.03);
}

.block-banners__item:hover .block-banners__item-image--blur {
  opacity: 1;
}

.block-banners__item-image {
  border-radius: 3px;
  z-index: -3;
  position: absolute;
  content: "";
  display: block;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  left: -10px;
  top: -10px;
  transition: transform .3s ease-out,opacity .3s ease-out;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.reflect-rtl {
  transform: scaleX(1);
}

.block-banners__item-image--blur {
  z-index: -2;
  opacity: 0;
  img {
    filter: blur(3px);
  }
}

.block-banners__item-title {
  display: block;
  font-size: 28px;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  color: #ffdf40;
}

.block-banners__item-details {
  display: block;
  margin-top: 12px;
  font-size: 15px;
}

.block-banners__item-button {
  margin-top: 28px;
}

@media (max-width: 1199.98px) {
  .block-banners__list {
    margin: -10px;
  }
  .block-banners__item {
    margin: 10px;
    width: calc(50% - 20px);
    height: 190px;
    padding: 24px;
  }
}
@media (max-width: 991.98px) {
  .block-banners__list {
    margin: -10px;
  }
  .block-banners__item {
    margin: 10px;
    width: calc(100% - 20px);
    height: auto;
    padding: 24px;
  }
}