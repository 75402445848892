.cityCard {
  padding: 0 5px;
  // & > div {
  //   background-color: #FAFAFA;
  // }
  img {
    border-radius: 2px;
    height: 200px;
    object-fit: cover;
    width: 100%;
  }
  .textbox-4_module__2gJjK {
    position: relative;
    overflow: hidden;
    border-radius: 3px;
    margin-bottom: 20px;
  }
  .textbox-4_module__2gJjK:hover .textbox-4_background__3bSqa {
    transform: scale(1.1);
  }
  .textbox-4_module__2gJjK:hover .textbox-4_content__1B-wJ {
    transform: translate(0,0);
  }
  .textbox-4_module__2gJjK:hover .textbox-4_background__3bSqa .wil-overlay {
    background-color: rgba(37,44,65,.1);
  }
  .textbox-4_background__3bSqa {
    position: relative;
    z-index: 8;
    transition: all ease .35s;
    .wil-overlay {
      z-index: 1;
      background-color: rgba(37,44,65,0);
      background-image: linear-gradient(0deg,rgba(8,0,6,.9) 15%,rgba(59,23,88,0) 55%);
      transition: all ease .3s;
    }
  }
  .wil-overlay {
    background-color: rgba(37,44,65,.7);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .textbox-4_content__1B-wJ {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    text-align: center;
    z-index: 9;
    transform: translate(0,80px);
    transition: transform ease .3s;
    span {
      border-radius: 5px !important;
    }
  }
  .textbox-4_title__pVQr7 {
    padding: 10px 0 30px 0;
    font-size: 20px;
    color: #fff;
    text-transform: uppercase;
  }
  .textbox-4_img__2_DKb {
    padding-top: 144%;
  }
  .bg-cover {
    background-size: cover;
    background-position: 50% 50%;
  }
  .textbox-4_img__2_DKb img {
    display: none;
  }
  .textbox-4_top__1919H {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    z-index: 9;
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    
    img {
      box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.3);
      background-color: rgba(255, 255, 255, 0.3); 
      width: 20px;
      height: 20px;
    }
    span {
      align-items: center;
      display: flex;
      margin-left: 20px;
    }
  }
  .color-primary {
    color: white !important;
  }
  .la {
    display: inline-block;
    font: normal normal normal 16px/1 "LineAwesome";
    font-size: inherit;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }
}

@media screen and (min-width: 769px) {
  .cityCard {
    .textbox-4_title__pVQr7 {
      font-size: 24px;
      line-height: 34px;
    }
  }
}