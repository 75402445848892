@import '../../colors.scss';

.searchService {
  .react-tabs {
    margin-top: 45px;
  }
  .react-tabs__tab-list {
    // border-bottom: 0px;
    // margin-bottom: 10px !important;
    .react-tabs__tab--selected {
      border-color: white !important;
      border-bottom: none;
      border-radius: 1px;
      color: white;
      transition: 0.5s;
      background-color: $searchcolor;
      &:focus {
        background-color: $searchcolor;
        border-color: $blue !important;
      }
    }
    .react-tabs__tab {
      border: 2px solid transparent;
          border-bottom: none;
      user-select: none;
      position: static;
      color: white;
      .react-tabs__tab:focus {
        box-shadow: none;
        border-color: none;
        outline: none;
      }
    }
  }
  .react-tabs__tab-panel {
    display: block !important;
    transition: 0.5s;
    opacity: 0;
  }
  .react-tabs__tab-panel--selected {
    opacity: 1;
  }
}

@media screen and (max-width: 576px) {
  .react-tabs__tab-list {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    font-size: 12px !important;
    border-bottom: none !important;
    .react-tabs__tab--selected {
      border-color: white !important;
      border-bottom: 2px solid transparent !important;
    }
  }
  .react-tabs__tab {
    padding: 5px 4px !important;
    font-size: 20px !important;
    text-align: center !important;
  }
}

