.toggleMenu {
   font-family: 'Acme', sans-serif;
   position: relative;
   .active {
      color: black !important;
      svg {
         color: black !important;
      }
      span {
         color: black !important;
      }
   }
   .left-menu {
      position: fixed;
      top: 60px;
      left: -300px;
      width: 300px;
      height: 100vh;
      overflow-y: auto;
      z-index: 101;
      background-color: white;
      padding: 0px 10px;
      transition: 0.5s;
      .first {
         border-bottom: 1px solid black;
         padding: 20px 0;
      }
      .second {
         svg {
            width: 30px;
            height: 30px;
            color: #888;
         }
         padding: 15px 0 15px 0;
         border-bottom: 1px solid black;
         div {
            display: flex;
            align-items: center;
            a {
               padding: 15px 0;
               width: 100%;
               display: flex;
               align-items: center;
               svg {
                  margin-right: 20px;
               }
               span {
                  font-size: 19px;
                  color: #888;
               }
            }
         }
      }

   }

   .modal-overlay {
      position: fixed;
      top: 60px;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: black;
      opacity: 0;
      z-index: -1;
      transition: 0.5s;
   }
}

@media screen and (max-width: 500px) {
   .left-menu {
      border-top: 1px solid #888;
      width: 200px !important;
      top: 70px !important;
   }
   .second {
      svg {
         width: 20px !important;
         height: 20px !important;
      }
      a {
         padding: 10px 0 !important;
         font-size: 15px !important;
      }
   }
}