.symptomsComp {
  .react-tabs {
    width: 100%;
    display: flex;
    .react-tabs__tab-list {
      padding-top: 20px;
      h1 {
        margin-bottom: 55px;
        margin-top: 20px;
        font: 32px/36px Arial;
      }
      flex: 30%;
      max-width: 30%;
      border-bottom: 0;
      display: flex;
      flex-direction: column;
      .react-tabs__tab {
        padding: 0;
        button {
          border: none;
          background: none;
          margin-left: -9px;
          border-radius: 5px;
          font-family: 'Arial';
          font-size: 15px;
          color: #333;
          cursor: pointer;
          padding: 4px 9px;
          border-radius: 4px;
          &:focus {
            outline: none;
          }
        }
      }
      .react-tabs__tab--selected {
        background:none;
        border: 0;
        border-color: #aaa;
        color: black;
        border-radius: 5px;
        button {
          background-color: #30aee7;
          color: white;
        }
      }
    }
    .react-tabs__tab-panel {
      flex: 70%;
      max-width: 70%;
      background-color: #3ea2e1;
      padding: 66px 0px 45px 40px
    }
  }
}

@media screen and (max-width: 992px) {
  .symptomsComp {
    .react-tabs {
      flex-wrap: wrap;
      .react-tabs__tab-list {
        padding: 0 10px;
        flex-direction: row;
        padding: 10px;
        flex: 100%;
        max-width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        &::-webkit-scrollbar {
          height: 5px;
        }
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }
        &::-webkit-scrollbar-thumb {
          background: #888;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
        .react-tabs__tab {
          padding: 0;
          button {
            // width: 100px;
            white-space: nowrap;
            &:focus {
              outline: none;
            }
          }
        }
        .react-tabs__tab--selected {
          background:none;
          border: 0;
          border-color: #aaa;
          color: black;
          border-radius: 5px;
          button {
            background-color: #30aee7;
            color: white;
          }
        }
      }
      .react-tabs__tab-panel {
        flex: 100%;
        max-width: 100%;
        background-color: #3ea2e1;
        padding: 10px;
      }
    }
  }
}