@import '../../colors.scss';

.service-card {
  margin-bottom: 15px;

  // padding-top: 15px;
  .product__info-card {
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    position: relative;
    width: 100%;

    .product__prices-stock {
      display: -moz-box;
      display: flex;
      -moz-box-pack: justify;
      justify-content: space-between;
      -moz-box-align: center;
      align-items: center;
      padding-top: 4px;
      padding-bottom: 22px;

      .product__prices {
        padding: 8px 0 7px;
        line-height: 1;

        .product__price {
          font-size: 30px;
          font-weight: 700;
          letter-spacing: -.04em;
          color: #262626;
        }

        .product__price--old {
          font-size: 16px;
          text-decoration: line-through;
          font-weight: 400;
          color: #999;
          margin-bottom: 5px;
        }

        .product__price--new {
          color: #262626;
        }
      }
    }

    .product--layout--full .product__info-body {
      padding-left: 20px;
      padding-right: 20px;
    }

    .product__info-body {
      padding: 28px 18px 14px;

      .product__badge {
        left: 22px;
        top: -7px;
        position: absolute !important;
        color: #fff;
        display: inline-block;
        position: relative;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 500;
        line-height: 1;
        z-index: 0;
        height: 18px;
        padding: 4px 14px 0;

        &:before {
          transform: skewX(-20deg);
          background: #f33;
          left: 3.5px;
          right: 3.5px;
          content: "";
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          z-index: -1;
          border-radius: 2.5px;
          transform-origin: center;
        }
      }
    }

    .product__actions {
      display: -moz-box;
      display: flex;
      white-space: nowrap;
      justify-content: space-between;
      align-items: center;

      .btn {
        height: 30px;
        padding: 0 10px;
      }
    }
  }
}

.product-card {
  position: relative;
  display: block;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);

  .product-card__actions-list {
    right: 0;
    position: absolute;
    overflow: hidden;
    z-index: 1;

    .product-card__action--compare,
    .product-card__action--wishlist {
      transform: translateX(100%) translateX(6px);
      transition: transform .2s, opacity .2s, color .12s, background .12s;
      opacity: 0;
      will-change: transform;
    }
  }

  .image__tag {
    display: block;
    // position: absolute;
    width: 100%;
    object-fit: cover !important;
    height: 200px;
  }

  .product-card__meta {
    font-size: 12px;
    line-height: 1;
    padding: 6px 16px;
    color: #999;
  }

  .product-card__name {
    padding: 0 16px;
    line-height: 1.1875;

    a {
      color: inherit;
      text-decoration: none;
      background-color: transparent;
    }
  }

  .product-card__rating {
    margin: -3px 0 -2px;
    padding: 8px 16px 0;
    display: -moz-box;
    display: flex;
    flex-wrap: wrap;
    color: #6c757d;
    font-size: .8125rem;
    align-items: center;

    .product-card__rating-stars {
      margin-right: 7px;
    }
  }

  .product-card__info {
    height: 70px;
  }

  .product-card__footer {
    padding: 16px;
    -moz-box-align: center;
    align-items: center;
    display: -moz-box;
    display: flex;
    -moz-box-ordinal-group: 3;
    order: 2;

    .product-card__addtocart-icon {
      position: relative;
      border: none;
      padding: 8px;
      border-radius: 2px;
      background: transparent;
      color: #ccc;
      z-index: 0;
      transition: color .2s;

      &::before {
        top: -2px;
        left: -2px;
        display: block;
        content: "";
        position: absolute;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        background: transparent;
        z-index: -1;
        border-radius: 50%;
        transform: scale(0);
        transition: transform .2s, background .2s;
      }

      svg {
        display: block;
        fill: currentColor;
      }
    }

    .product-card__prices {
      -moz-box-flex: 1;
      flex-grow: 1;
      font-size: 18px;
      display: -moz-box;
      display: flex;
      -moz-box-align: end;
      align-items: flex-end;
      flex-wrap: wrap;
      font-weight: 500;
      letter-spacing: -.04em;
      line-height: 1;
      color: #262626;
    }
  }

  &:hover {
    .product-card__actions-list {

      .product-card__action--compare,
      .product-card__action--wishlist {
        transform: none;
        opacity: 1;
      }
    }

    .product-card__footer {
      .product-card__addtocart-icon {
        color: #fff;

        &::before {
          transform: scale(1);
          background: $blue;
        }

        &:hover {
          &:before {
            background: #333;
          }
        }
      }
    }
  }
}

.product-card__action {
  position: relative;
  display: block;
  padding: 8px;
  margin: 0;
  border: none;
  transition: color .08s, background .08s;
  background-color: #fff;
  color: #ccc;

  &:hover {
    background-color: #f2f2f2;
    color: #4d4d4d;
  }

  svg {
    fill: currentColor;
    display: block;
  }

  &:last-child {
    border-bottom-left-radius: 2px;
  }
}

@media (min-width: 576px) {
  .oneIllnessModal {
    max-width: 800px !important;
  }
}

@media (max-width: 576px) {
  .product-card {
    display: flex;

    img {
      width: 60px;
    }

    .product-card__image {
      width: 60px;
    }

    .product-card__info {
      height: 60px;
    }
  }
}