.contactUs {
  .our-map-location {
    &>div {
      width: 100% !important;
    }
  }

  .contact-us__address {
    p {
      margin-top: 0;
      padding-bottom: 1rem;
      line-height: 1.3;
    }
  }
}