@import '../colors.scss';

.homePage {
  .searchNumbers {
    background-color: $blue;
    background-color: black;
    position: relative;
    padding-top: 24px;
    z-index: 2;
    padding-bottom: 150px;
    h1 {
        color: white;
        font: 38px/50px Arial;
        text-align: center;
        margin-bottom: 40px;
    }
    .block-finder__image {
      background-image: url('/images/bg-home.jpg');
      height: 100%; 
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-position: 50%;
      opacity: .22;
      z-index: -1;
    }
  }
  .numbers {
    background-color: white;
  }
}

.conveniences__home {
  position: relative;
  h2, h6, h4, p {
    color: black;
  }
  .conveniences__background {
    background: linear-gradient(#1A8194, #FFFFFF);
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-position: 50%;
    opacity: .22;
    z-index: -1;
  }
}