.clinic-location {
  cursor: pointer;
  display: flex;
  align-items: center;
  svg {
    width: 28px;
    height: 28px;
  }
  span {
    font-size: 15px;
    margin-right: 10px;
    text-decoration: underline;
    opacity: 0.7;
  }
}

.mapModal {
  & > div > div > div {
    width: 100% !important;
    height: 100% !important;
  }
}