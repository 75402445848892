@import '../../colors.scss';

.numbersComp {
   border-bottom: 2px solid #ebebeb;
   padding: 30px 0;
   background-color: white;
   .numbers-item {
      .numbers-item-img {
         margin-right: 5px;
         svg {
            fill: $blue;
            height: 48px;
            width: 48px;
         }
      }
      .numbers-item-inf {
         p {
            font-weight: 500;
            transition: 0.5s;
            font-size: 25px;
            line-height: 1.125;
            padding: .16667em 0;
         }
         span {
            font-size: 14px;
            line-height: 1.25;
            padding: .11111em 0;
         }
         &:hover {
            p {
               opacity: 1;
            }
            span {
               opacity: 1;
            }
         }
      }
   }
}

@media screen and (max-width: 768px) {
   .numbersComp {
      .numbers-item {
         margin-bottom: 10px;
         flex: 50%;
         max-width: 50%;
         flex-direction: column;
         align-items: center;
         .numbers-item-img {
            margin-right: 20px;
         }
         .numbers-item-inf {
            text-align: center;
         }
      }
   }
}

@media screen and (max-width: 576px) {
   .numbersComp {
      .numbers-item {
         margin-bottom: 10px;
         flex: 100%;
         max-width: 100%;
         flex-direction: row;
         margin-left: 20px;
         .numbers-item-inf {
            text-align: start;
         }
      }
   }
}

