.newsComp {
  background-color: #FAFAFA;
  .container-lg:nth-child(2) {
    display: flex;
    -moz-box-pack: center;
    justify-content: center;
  }
  .blog-view__item-posts {
    -moz-box-flex: 1;
    flex-grow: 1;
    .posts-list__body {
      margin: -14px;  
    }
  }
  .widget+.widget {
    margin-top: 20px;
  }
  .widget-posts__item {
    margin-top: 1rem;
  }
  .widget-posts__list {
    list-style: none;
    padding: 0 1.5rem 1.5rem;
    margin: 0;
  }
  .widget-posts__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .widget-posts__item {
    display: -moz-box;
    display: flex;
  }
  .widget-posts__image {
    flex-shrink: 0;
    position: relative;
    margin-right: 14px;
    &:before {
      left: 0;
      right: 0;
      position: absolute;
      display: block;
      content: "";
      top: 0;
      bottom: 0;
      background: rgba(26,26,26,.2);
      border-radius: 1.5px;
      opacity: 0;
      transition: opacity .2s;
      pointer-events: none;
    }
    img {
      width: 70px;
      height: 70px;
      display: block;
      border-radius: 1.5px;
      object-fit: cover;
    }
  }
  .widget-posts__name {
    font-size: 15px;
    line-height: 19px;
    overflow: hidden;
    max-height: 38px;
    a {
      color: inherit;
      transition: color .12s;
    }
  }
  .widget-posts__date {
    font-size: 13px;
    color: #999;
    margin-top: 3px;
    position: relative;
    padding-left: 22px;
    &:before {
      left: 0;
      position: absolute;
      display: block;
      content: "";
      height: 1px;
      width: 16px;
      background: currentColor;
      opacity: .6;
      top: 10px;
    }
  }
  .blog-view__item+.blog-view__item {
    margin-left: 1rem;
  }
  .blog-view__item-sidebar {
    width: 332px;
    flex-shrink: 0;
    .widget-search__form {
      display: -moz-box;
      display: flex;
      position: relative;
      z-index: 0;
      .widget-search__input {
        -moz-box-flex: 1;
        flex-grow: 1;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        background: none;
        padding: 15.5px 24px;
        font-family: inherit;
        font-size: 16px;
        line-height: 21px;
        &:focus {
          outline: none;
        }
      }
      .widget-search__button {
        flex-shrink: 0;
        border: none;
        background: none;
        display: -moz-box;
        display: flex;
        -moz-box-pack: center;
        justify-content: center;
        -moz-box-align: center;
        align-items: center;
        color: #bfbfbf;
        fill: currentColor;
        width: 52px;
        height: 52px;
        padding: 0;
        &:hover {
          color: #a6a6a6;
          background: #f5f5f5;
        }
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .post-card--layout--list {
    .post-card__image {
      a {
        width: 100%;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .post-card--layout--list {
    .post-card__image {
      width: 280px;
      height: 220px;
      a {
        width: 100%;
      }
    }
    .post-card__content {
      padding: 20px 26px 22px;
      .post-card__category {
        margin-bottom: 6px;
      }
      .post-card__title {
        margin-bottom: 8px;
        h2 {
          font-size: 17px;
          line-height: 24px;
        }
      }
      .post-card__date {
        -moz-box-ordinal-group: 2;
        order: 1;
        &:before {
          display: block;
          width: 32px;
          margin-top: 16px;
          margin-bottom: 8px;
        }
      }
      .post-card__excerpt {
        font-size: 15px;
        max-height: 66px;
        .typography {
          line-height: 22px;
        }
      }
      .post-card__more {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .newsComp {
    .container-lg:nth-child(2) {
      -moz-box-orient: vertical;
      -moz-box-direction: normal;
      flex-direction: column;
    }
    .blog-view__item+.blog-view__item {
      margin-left: 0;
    }
    .blog-view__item-sidebar {
      width: 100%;
      margin-top: 48px;
      -moz-box-ordinal-group: 2;
      order: 1;
    }
  }
}

@media (min-width: 768px) {
  .post-card--layout--list {
    .post-card__title {
      a:hover {
        text-decoration: underline !important;
      }
    }
  }

}

@media (min-width: 768px) and (max-width: 991.98px) {
  .post-card--layout--list {
    .post-card__image {
      width: 320px;
      height: 260px;
      a {
        width: 100%;
      }
    }
    .post-card__content {
      padding: 26px 28px 24px;
      .post-card__category {
        margin-top: -2px;
        margin-bottom: 10px;
      }
      .post-card__title {
        margin-bottom: 10px;
        h2 {
          font-size: 20px;
          line-height: 26px;
          color: inherit;
        }
      }
      // .post-card__excerpt {
      //   max-height: 78px;
      // }
      .post-card__date {
        -moz-box-ordinal-group: 2;
        order: 1;
        &:before {
          display: block;
          width: 36px;
          margin-top: 14px;
          margin-bottom: 10px;
        }
      }
      .post-card__more {
        display: none;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .post-card--layout--list {
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    .post-card__image {
      img {
        max-width: 100%;
        height: auto;
        width: 100%;
      }
    }
    .post-card__content {
      position: relative;
      .post-card__category {
        left: 0;
        position: absolute;
        bottom: calc(100% - 1px);
        font-weight: 500;
        z-index: 0;
        font-size: 14px;
        background: white;
        &::before {
          left: 0;
          border-top-right-radius: 2px;
          transform: skewX(
      30deg
      );
          transform-origin: left top;
          width: 100%;
        }
        &::after, &::before {
          position: absolute;
          display: block;
          content: "";
          top: 0;
          background: #fff;
          z-index: -1;
          height: 100%;
        }
      }
      .post-card__title {
        a {
          color: inherit;
          &:hover {
            text-decoration: underline !important;
          }
        }
      }
      .post-card__excerpt {
        overflow: hidden;
      }
      .post-card__date {
        font-size: 14px;
        color: #999;
        &:before {
          display: block;
          content: "";
          height: 1px;
          background: currentColor;
          opacity: .6;
        }
        a {
          color: inherit;
        }
      }
    }
  }
}

@media (max-width: 767.98px) and (min-width: 475px) {
  .post-card--layout--list {
    .post-card__content {
      padding: 1.675rem 1.675rem 1.75rem;
      .post-card__category {
        height: 26px;
        padding: 1.125rem 1.675rem 0;
      }
      .post-card__title {
        h2 {
          font-size: 20px;
          line-height: 28px;
        }
      }
      .post-card__date {
        margin-top: 10px;
        margin-bottom: 12px;
        &:before {
          position: relative;
          top: -1px;
          display: inline-block;
          vertical-align: middle;
          width: 32px;
          margin-right: 4px;
        }
      }
    } 
  }
}

@media screen and (max-width: 474px) {
  .post-card__content {
    display: flex;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
    padding: 1.5rem 1.25rem 1.125rem;
    .post-card__category {
      height: 22px;
      padding: .875rem 1.25rem 0;
      font-size: 13px;
    }
    .post-card__title {
      h2 {
        font-size: 18px;
        line-height: 24px;
      }
    }
    .post-card__date {
      -moz-box-ordinal-group: 2;
      margin-top: 12px;
      margin-bottom: 12px;
      &:before {
        display: block;
        width: 32px;
        margin-bottom: 8px;
      }
    }
    .post-card__excerpt {
      font-size: 15px;
      max-height: 96px;
      .typography {
        line-height: 24px;
      }
    }
  }
}