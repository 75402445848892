.clinicItem {
  padding: 0 5px;
  // & > div {
  //   background-color: #FAFAFA;
  // }
  img {
    border-radius: 2px;
    height: 200px;
    object-fit: cover;
  }
  h2 {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
  p {
    color: #6b6b6b;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  .clinic-home-rate {
    display: flex;
    // justify-content: space-between;
    align-items: center;
  }
  .ant-rate  {
    font-size: 14px !important;
    line-height: 0.5 !important;
  }
  .ant-rate-star:not(:last-child) {
    margin-right: 3px;
  }
  .ant-rate-star svg {
    width: 10px;
    height: 10px;
  }

  .ant-rate-star.ant-rate-star-full,
  .ant-rate-star.ant-rate-star-zero,
  .ant-rate-star.ant-rate-star-half.ant-rate-star-active {
      transition: scale(0.95);
  }

  .ant-rate-star.ant-rate-star-half.ant-rate-star-active:hover {
      transform: scale(0.95);
  }

  .ant-rate-star.ant-rate-star-full:hover {
      transform: scale(0.95);
  }

  .ant-rate-star.ant-rate-star-zero:hover {
      transform: scale(0.95);
  }
}