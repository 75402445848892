.product__tabs {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}

.product-tabs__list {
    display: -moz-box;
    display: flex;
    padding: 0;
    margin: 0 0 -1px;
    list-style: none;
    overflow-x: auto;
    justify-content: center;
}

.product-tabs__item {
  position: relative;

  &:first-child {
    margin-left: auto;
  }
  &:last-child {
    margin-right: auto;
  }
  a {
    font-size: 16px;
    font-weight: 500;
    color: inherit;
    display: -moz-box;
    display: flex;
    padding: 14px 18px 13px;
    transition: background .12s,box-shadow .12s;
    &:hover {
      background: #f7f7f7;
      box-shadow: inset 0 -1px #e5e5e5;
    }
  }
}

.product-tabs__item--active a, .product-tabs__item--active a:active, .product-tabs__item--active a:hover {
  background: transparent;
  box-shadow: inset 0 -2px #e52727;
}

.product-tabs__content {
    border-top: 1px solid #ebebeb;
}

.product-tabs--layout--full  {
  .product-tabs__pane--active {
    padding: 28px;
  }
}

.product-tabs__pane--active {
    padding: 22px 28px 28px;
    overflow: visible !important;
    height: auto !important;
    opacity: 1 !important;
    max-width: calc(100% - .001px);
}

.product-tabs__pane {
    display: block;
    overflow: hidden;
    height: 0;
    opacity: 0;
    transition: opacity .5s;
    & > div {
      height: 250px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 5px;
      }
    }
}

.spec {
    font-size: 14px;
    line-height: 18px;
}

.spec__section-title {
    font-weight: 500;
    font-size: 17px;
    letter-spacing: -.03em;
    line-height: 20px;
    margin: 0 0 12px;
    padding-bottom: 8px;
}

.spec__row {
    padding: 8px 0;
    display: -moz-box;
    display: flex;
    border-bottom: 1px solid #ebebeb;
}

.spec__name {
  padding-right: 30px;
  width: 230px;
  flex-shrink: 0;
  color: #6c757d;
}

.spec__section+.spec__section {
    margin-top: 40px;
}

.spec__disclaimer {
    margin-bottom: 20px;
    font-size: 13px;
    line-height: 20px;
    color: #6c757d;
}

.analogs-table {
  display: block;
  table {
    width: 100%;
    font-size: 15px;  
  }
}

.analogs-table__product-name {
    color: inherit;
    transition: color .12s;
}

.analogs-table__country, .analogs-table__sku {
    color: #6c757d;
    font-size: 13px;
    line-height: 1;
    margin-top: 1px;
    margin-bottom: 4px;
}

@media (max-width: 992.98px) {
  .product__tabs {
    margin-top: 24px;
  }
}

@media (min-width: 768px) {
  .analogs-table {
    thead {
      font-size: 13px;
      text-transform: uppercase;
      td, th {
        padding-bottom: 6px;
      }
    }
    td, th {
      padding: 0;
    }
    td:first-child, th:first-child {
      padding-left: 14px;
    }
    td:not(:first-child), th:not(:first-child) {
      padding-left: 16px;
    }
    .analogs-table__column--brand, .analogs-table__column--rating {
      text-align: center;
    }
    td:last-child, th:last-child {
      padding-right: 14px;
    }
    .analogs-table__column--price {
      text-align: right;
    }
    tbody td:first-child, tbody th:first-child {
      border-left: 1px solid #ebebeb;
    }
    tbody td, tbody th {
      border-top: 1px solid #ebebeb;
      padding: 10px 0;
    }
    tbody td:last-child, tbody th:last-child {
      border-right: 1px solid #ebebeb;
    }
    tbody tr:hover td, tbody tr:hover th {
      background: #f7f7f7;
    }
  }
}

@media (max-width: 991.98px) {
  .product-tabs--layout--full {
    .product-tabs__pane--active {
      padding: 20px 24px 24px;
    }
  }
}

@media (max-width: 767.98px) {
  .product-tabs--layout--full {
    .product-tabs__pane--active {
      padding: 14px 18px 18px;
    }
  }
  .analogs-table {
    table {
      display: block;
      font-size: 15px;
    }
    thead {
      display: none;
    }
    tbody, td, tr {
      display: block;
    }
    tr {
      padding: 14px 16px;
      background: #f5f5f5;
      border-radius: 2px;
    }
    td {
      padding: 0;
    }
    .analogs-table__column--rating {
      margin-top: 11px;
    }
    .analogs-table__column--brand {
      font-size: 13px;
      padding-top: 8px;
      margin-top: 12px;
      border-top: 1px solid #e5e5e5;
    }
    .analogs-table__column--price {
      font-weight: 500;
      margin-top: 16px;
    }
  }
}

@media (max-width: 575.98px) {
  .spec__row {
    display: block;
    padding: 8px 0;
  }
  .product-tabs__item {
    position: relative;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0 !important;
    }
    a {
      font-size: 13px !important;
      font-weight: 500;
      color: inherit;
      display: -moz-box;
      display: flex;
      padding: 14px 5px 13px;
      transition: background .12s,box-shadow .12s;
      &:hover {
        background: #f7f7f7;
        box-shadow: inset 0 -1px #e5e5e5;
      }
    }
  }
}