@import "../../colors";

.lastNews {
  padding-bottom: 50px;

  .slick-next {
    right: -5px;
    width: 20px;
    height: 20px;
  }

  .slick-prev {
    left: -5px;
    z-index: 2;
    width: 20px;
    height: 20px;
  }

  .slick-prev:before,
  .slick-next:before {
    font-family: FontAwesome;
    font-size: 25px;
    line-height: 1;
    opacity: 1;
    width: 20px;
    height: 20px;
  }

  // .next-arrow, .prev-arrow {
  //   height: 23px;
  //   padding: 0 14.37131px;
  //   display: -moz-box;
  //   display: flex;
  //   position: relative;
  //   z-index: 0;
  //   -moz-box-align: center;
  //   align-items: center;
  //   border: none;
  //   background: transparent;
  //   pointer-events: none;
  //   transition: color .15s;
  //   color: #fff;
  //   position: absolute;
  //   cursor: pointer;
  //   &:hover:before {
  //     background: #333;
  //   }
  //   &::before {
  //     transform: skewX(-20deg);
  //     left: 4.18566px;
  //     width: calc(100% - 8.37131px);
  //     position: absolute;
  //     display: block;
  //     content: "";
  //     height: 100%;
  //     transform-origin: center center;
  //     z-index: -1;
  //     border-radius: 2px;
  //     pointer-events: auto;
  //     transition: background .15s;
  //     background: $blue;
  //   }
  //   &:after {
  //     position: absolute;
  //     display: block;
  //     content: "";
  //     top: -2px;
  //     width: calc(100% + 4px);
  //     height: calc(100% + 4px);
  //     transition: background .2s;
  //     opacity: .01;
  //     left: -2px;
  //   }
  //   svg {
  //     polygon {
  //       fill: white;
  //     }
  //   }
  // }
  // .next-arrow {
  //   top: -54px;
  //   right: 0;
  // }
  // .prev-arrow {
  //   top: -54px;
  //   right: 50px;
  // }
}

.section-header__body {
  margin-top: 50px;
  margin-bottom: 20px;
  display: -moz-box;
  display: flex;
  flex-wrap: wrap;
}

.section-header__title {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  position: relative;
  top: 2px;
}

.section-header__spring {
  -moz-box-flex: 1;
  flex-grow: 1;
}

.section-header__links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: -moz-box;
  display: flex;
  font-size: 14px;
  flex-wrap: wrap;
}

.section-header__links-item {
  display: flex;
  align-items: center;
}

.section-header__links-link {
  color: #6c757d;
  position: relative;
  transition: color .1s;
}