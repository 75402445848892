.posts-list__item {
  margin: 14px;
  .typography {
    line-height: 1.625;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@media screen and (min-width: 768px) {
  .post-card--layout--list {
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    display: -moz-box;
    display: flex;
    .post-card__image {
      display: -moz-box;
      display: flex;
      flex-shrink: 0;
      padding: 26px 28px 24px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .post-card__content {
      position: relative;
      flex: 1;
      flex-wrap: wrap;
      -moz-box-pack: justify;
      justify-content: space-between;
      -moz-box-align: center;
      align-items: center;
      align-content: flex-start;
      .post-card__category {
        font-size: 14px;
        font-weight: 500;
        a {
          color: #007bff;
        }
      }
      .post-card__title h2 {
        margin-bottom: 0;
        a {
          color: inherit;
        }
      }
      .post-card__date {
        font-size: 14px;
        color: #999;
        &:before {
          content: "";
          height: 1px;
          background: currentColor;
          opacity: .6;
        }
        a {
          color: inherit;
        }
      }
      .post-card__excerpt {
        overflow: hidden;
        -webkit-line-clamp: 3;
        margin-bottom: auto;
      }
    }
  }
}

@media (min-width: 1200px) {
  .post-card--layout--list{
    .post-card__image  {
      width: 320px;
      height: 260px;
     }
    .post-card__content {
      padding: 26px 28px 24px;
      .post-card__category {
        margin-top: -2px;
        margin-bottom: 10px;
      }
      .post-card__title {
        margin-bottom: 10px;
        h2 {
          font-size: 20px;
          line-height: 26px;
        }
      }
      .post-card__date {
        -moz-box-ordinal-group: 2;
        order: 1;
        &:before {
          display: block;
          width: 36px;
          margin-top: 14px;
          margin-bottom: 10px;
        }
      }
      .post-card__more {
        display: none;
      }
      // .post-card__excerpt {
      //   max-height: 78px;
      // }
    }
  }
}
