.package-card {
  position: relative;
  .ant-card-meta-title {
    white-space: normal !important;
  }
  .package-sort{
    position: absolute;
    top: 10px;
    left: 10px;
  }
  .package-info {
    background-color:white;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 30px;
      height: 30px;
    }
  }
}