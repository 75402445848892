@import '../../colors.scss';

.next-arrow, .prev-arrow {
  svg {
    polygon {
      fill: black;
    }
  }
}

@media screen and (max-width: 992px) {
  .cities {
      .slick-next {
        right: -5px;
        width: 20px;
        height: 20px;
      }
      .slick-prev {
        left: -5px;
        z-index: 2;
        width: 20px;
        height: 20px;
      }
      .slick-prev:before, .slick-next:before {
        font-family: FontAwesome;
        font-size: 25px;
        line-height: 1;
        opacity: 1;
        width: 20px;
        height: 20px;
      }
  }
}