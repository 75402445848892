@import '../../colors.scss';

.doctorCard {
  .clinic-card {
    display: -moz-box;
    display: flex;
    position: relative;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    .clinic-card__image {
      .image__tag {
        display: block;
        width: 120px;
        height: 120px;
        margin: auto;
        object-fit: cover;
        border-radius: 50%;
        object-position: 50% top;
      }
      .stage {
        margin-top: 10px;
        padding: 1px 3px 7px;
      }
    }
    .clinic-card__info {
      padding-left: 0;
      padding-right: 20px;
      padding-top: 16px;
      padding-bottom: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
      .clinic-card__name {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        a {
          color: inherit;
          text-decoration: none;
          background-color: transparent;
        }
      }
      .clinic-card__features {
        font-size: 14px;
        color: #6c757d;
        line-height: 21px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;  
        overflow: hidden;
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          li {
            padding-left: 12px;
            position: relative;
            &:before {
              display: block;
              position: absolute;
              content: "";
              width: 3px;
              height: 3px;
              border-radius: 1.5px;
              background: currentColor;
              top: 9px;
              opacity: .7;
              left: 2px;
            }
          }
        }
      }
    }
    .clinic-card-rate {
      display: flex;
      align-items: center;
      justify-content: center;
      .ant-rate  {
        font-size: 14px !important;
      }
      .ant-rate-star:not(:last-child) {
        margin-right: 5px;
      }
      .ant-rate-star svg {
        width: 16px;
        height: 16px;
      }

      .ant-rate-star.ant-rate-star-full,
      .ant-rate-star.ant-rate-star-zero,
      .ant-rate-star.ant-rate-star-half.ant-rate-star-active {
          transition: scale(0.95);
      }

      .ant-rate-star.ant-rate-star-half.ant-rate-star-active:hover {
          transform: scale(0.95);
      }

      .ant-rate-star.ant-rate-star-full:hover {
          transform: scale(0.95);
      }

      .ant-rate-star.ant-rate-star-zero:hover {
          transform: scale(0.95);
      }
    }
    
  }
}

.service-modal {
  .ant-radio-group {
    display: block;
    .ant-space-vertical{
      width: 100%;
      .ant-radio-button-wrapper{
        width: 100%;
        height: auto !important;
        span:nth-child(2) {
          p {
            flex: 1;
            line-height: 1.6;
          }
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
  .service-modal-title {
    display: flex;
    align-items: center;
    .ant-tag {
      cursor: pointer;
    }
  }
}

.timeTable {
  .react-tabs {
    display: flex;
    border: 1px solid #3c3e43;
    color: white;
    background: #1c1d1f;
  }
  .react-tabs__tab-list {
    // overflow-y: auto;
    display: flex;
    flex-direction: column;
    width: 170px;
    margin: 0;
    padding: 0;
    color: white;
    background: #3c3e43;
    border-bottom: 0px solid #aaa;
  }

  .react-tabs__tab {
    height: 70px;
    list-style: none;
    padding: 12px 6px;
    cursor: pointer;
    color: #bbb;
    border: 0px;
  }

  .react-tabs__tab--selected {
    background: #1c1d1f;
    border-color: #1c1d1f;
    border-left: 0px solid #6eb800;
    color: white;
    border-radius: 0 0 0 0;
  }

  .react-tabs__tab-panel {
    display: none;
    width: 300px;
  }

  .react-tabs__tab-panel--selected {
    display: block;
  }

  .react-tabs__tab {
    padding-left: 24px;
  }

  .react-tabs__tab--selected {
    padding-left: 21px;
  }

  .panel-content {
    text-align: center;
  }

}

.doctorsList30ItemSpecialisation {
  color: #999999;
  font: normal 12px/18px Arial;
}


.doctor-about-price {
  flex: 1;
  padding-left: 30px;
  .stage {
    margin-bottom: 10px;
    margin-top: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  .doctorCard {
    .clinic-card {
      .clinic-card__image {
        padding: 16px 24px;
        width: 230px;
      }
      .clinic-card__footer {
        border-left: 1px solid #ebebeb;
        width: 220px;
        -moz-box-align: center;
        align-items: center;
        display: -moz-box;
        display: flex;
        -moz-box-ordinal-group: 3;
        order: 2;
        flex-shrink: 0;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        flex-direction: column;
        padding: 20px;
        .clinic-card__prices {
          padding: 28px 0;
          font-size: 20px;
          display: -moz-box;
          display: flex;
          -moz-box-align: end;
          align-items: flex-end;
          flex-wrap: wrap;
          -moz-box-pack: center;
          justify-content: center;
        }
        .clinic-card__addtocart-full {
          display: block;
          height: 36px;
          padding: 0 20px;
          width: 100%;
          margin-bottom: 10px;
          position: relative;
          background: $blue;
          color: #fff;
          border: none;
          border-radius: 2px;
          font-weight: 500;
          transition: background-color .15s,color .15s;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .doctorCard {
    .clinic-card {
      -moz-box-orient: vertical;
      -moz-box-direction: normal;
      flex-direction: column;
      .clinic-card__image {
        padding: 20px;
        width: 100%;
        display: -moz-box;
        display: flex;
        position: relative
      }
      .clinic-card__info {
        padding: 0 20px 20px;
        .clinic-card__features {
          padding-top: 12px;
        }
      }
      .clinic-card__footer {
        border-top: 1px solid #ebebeb;
        -moz-box-orient: horizontal;
        -moz-box-direction: normal;
        flex-direction: row;
        flex-wrap: wrap;
        -moz-box-align: center;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        .clinic-card__prices {
          padding-top: 20px;
          padding-bottom: 16px;
          display: -moz-box;
          display: flex;
          -moz-box-align: end;
          align-items: flex-end;
          flex-wrap: wrap;
        }
        .clinic-card__addtocart-full {
          height: 30px;
          padding: 0 16px;
          font-size: 15px;
          position: relative;
          background: $blue;
          color: #fff;
          border: none;
          border-radius: 2px;
          font-weight: 500;
          transition: background-color .15s,color .15s;
        }
      }
    }
    .clinic-location {
      margin-top: 15px;
    }
  }
}