* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // font-family: 'Acme', sans-serif;
  font-family: medPayFont;
}

@font-face {
  font-family: meyPayFont;
  src: url('static/fonts/HelveticaNeue\ Bold.ttf');
}

@import "colors";
@import url('./style/Filter.scss');
@import url("./style/Properties.scss");

@mixin df-jc-ac {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: #555;
}

body {
  // background-color: #ddd;
  line-height: 1 !important;
}

p,
h2,
h3,
h4,
h5,
ul,
address {
  margin-bottom: 0 !important;
}

a {
  &:hover {
    text-decoration: none !important;
  }
}

.card-body--padding--2 {
  padding: 2rem;
}

.card {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  border: none !important;
  border-radius: 0 !important;
}

table {
  border-collapse: collapse;
}

.btn-primary,
.btn-primary.disabled,
.btn-primary:disabled {
  border-color: $blue !important;
  background: $blue !important;
  color: #fff !important;

  &:focus {
    border-color: #333 !important;
    background: #333 !important;
    color: #fff !important;
    box-shadow: none !important;
  }

  &:hover {
    border-color: #333 !important;
    background: #333 !important;
    color: #fff !important;
  }
}

.btn-group-sm>.btn:not(.btn-icon),
.btn-sm:not(.btn-icon) {
  font-size: 14px;
  line-height: 17px;
  padding: 6.5px 12px;
}

.btn.disabled,
.btn:disabled {
  opacity: .65;
}


button:disabled {
  cursor: not-allowed;
}

.btn {
  border: none;
  border-radius: 2px;
  fill: currentColor;
  font-weight: 500;
  display: inline-block;
  user-select: none;

  &:focus {
    border-color: #333;
    background: #333;
    color: #fff;
    box-shadow: none;
    outline: none;
    // border: none;
  }
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

.alert a {
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  color: currentColor;
  background: hsla(0, 0%, 100%, .5);
  border-radius: 1.5px;
  padding: 0 3px;
}

.form-control:focus {
  color: #495057 !important;
  background-color: rgba(147, 211, 195, 0.342) !important;
  border-color: black !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0 !important;
}

.alert-lg {
  font-size: 16px;
  padding: 1.125rem 1.375rem !important;
}

.section-header__divider {
  width: 100%;
  height: 2px;
  margin-top: 8px;
  background: #ebebeb;
}

.top-bottom {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #FAFAFA;
}

.clinic-distance {

  .custom-control-label::before,
  .custom-control-label::after {
    top: 0 !important;
  }
}

.linkM {
  height: 30px;
  padding: 0 16px;
  font-size: 15px;
  position: relative;
  background: #1a8194;
  color: #fff;
  border: none;
  border-radius: 2px;
  font-weight: 500;
  transition: background-color 0.15s, color 0.15s;
  @include df-jc-ac;

  &:hover {
    background-color: black;
    color: white;
  }
}

.block-header__title {
  font-size: 2.25rem;
  font-weight: 700;
  margin: 0;
  text-align: center;
}

.sort-card {
  padding: .75rem 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  margin-bottom: 1rem;

  .view-options {
    font-size: .9375rem;
    overflow: hidden;
  }

  .view-options__spring {
    margin-left: 20px;
  }

  .view-options__select {
    margin-left: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    label {
      margin-right: 10px;
      margin-bottom: 0;
      white-space: nowrap;
      font-size: 15px;
      display: inline-block;
    }

    select.form-control-sm {
      background-position: right 8px center;
    }
  }
}

.element-card {
  margin-top: 15px;

  .title {
    font-size: 18px;
    color: #2c928d;
    color: #006d68;
    text-decoration: none;
    margin-bottom: 5px;
  }

  .content {
    text-align: justify;
    position: relative;
    font-size: 16px;
    color: #c2c2c2 !important;
    color: #b5b5b5 !important;
    line-height: 24px;
    position: relative;
    margin-bottom: 15px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    &:hover {
      color: #696969 !important;
    }
  }
}

// antd 

.ant-form-item-label>label {
  font-size: 18px !important;
  height: auto !important;
}

.ant-form-item-children-icon {
  top: auto !important;
}

.ant-divider-horizontal {
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
  margin: 5px 0 !important;
}

.ant-checkbox-group {
  display: flex !important;
  flex-direction: column !important;
}

.ant-back-top-icon {
  line-height: 24px !important;
}

.ant-back-top {
  right: 40px !important;
  bottom: 50px !important;
  z-index: 10;
}

.ant-modal-close-x {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.ant-modal-title {
  font-weight: 500;
  font-size: 20px !important;
  text-align: center;
}

.ant-tabs-left>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab {
  margin: 1px 0 0 0 !important;
}

.ant-pagination {
  .anticon {
    vertical-align: 0.3em !important;
  }
}

.ant-upload.ant-upload-select-picture-card {
  width: 100% !important;
  min-height: 200px !important;
}

.ant-upload-list-picture-card-container {
  width: 100% !important;
  min-height: 200px !important;
  height: auto !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $blue !important;
  text-shadow: 0 0 0.25px currentColor;
}

// Other libraries

.infinite-scroll-component {
  overflow: hidden !important;
}

.ymaps-2-1-79-map {
  width: 100% !important;
  height: 100% !important;
}

.react-yandex-map {
  width: 100% !important;
  height: 100% !important;
}

.lazy-load-image-background {
  width: 100%;

  img {
    width: 100%;
  }
}

.cartInModal {
  .rccs {
    width: 100%;
    margin-bottom: 10px;
  }

  .rccs__card {
    height: 182.873px;
    margin: 0 auto;
    position: relative;
    transform-style: preserve-3d;
    transition: all 0.4s linear;
    width: 100%;
  }
}

.creditCart {
  position: relative;

  &:hover {
    .cart-actions {
      display: block;
    }
  }
}

.cart-actions {
  position: absolute;
  display: none;
  top: 10px;
  right: 10px;

  button:first-child {
    margin-right: 10px;
  }
}

.ant-btn-primary {
  background: $blue !important;
  border-color: $blue !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $blue !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: $blue !important;
}

.ant-radio-inner::after {
  background-color: $blue !important;
}

.ant-slider-handle {
  border: solid 2px $blue !important;
}

.ant-slider-track {
  position: absolute;
  height: 4px;
  background-color: $blue !important;
  border-radius: 2px;
  transition: background-color 0.3s;
}

.comment-rate {
  display: flex;
  align-items: center;

  &>h4 {
    margin-right: 4px;
    text-transform: uppercase;
    font-size: 15px;
  }

  .ant-rate {
    font-size: 14px !important;
    line-height: 0.5 !important;
  }

  .ant-rate-star:not(:last-child) {
    margin-right: 1px;
  }

  .ant-rate-star svg {
    width: 10px;
    height: 10px;
  }

  .ant-rate-star.ant-rate-star-full,
  .ant-rate-star.ant-rate-star-zero,
  .ant-rate-star.ant-rate-star-half.ant-rate-star-active {
    transition: scale(0.95);
  }

  .ant-rate-star.ant-rate-star-half.ant-rate-star-active:hover {
    transform: scale(0.95);
  }

  .ant-rate-star.ant-rate-star-full:hover {
    transform: scale(0.95);
  }

  .ant-rate-star.ant-rate-star-zero:hover {
    transform: scale(0.95);
  }
}

// .slick-cloned {
//   display: none !important;  
// }

@media (min-width: 1200px) {
  .block-header__breadcrumb+.block-header__title {
    margin-top: -77px;
  }
}

@media (min-width: 1200px) {
  .block-header__title {
    margin: 0 auto;
  }
}

@media screen and (max-width: 992px) {
  .order-success__meta-list {
    flex-wrap: wrap;
  }

  .sort-card {

    .view-options__legend,
    .view-options__select,
    .view-options__spring {
      margin-left: 0px !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .ant-back-top {
    right: 20px !important;
    bottom: 30px !important;
  }
}

@media screen and (max-width: 500px) {
  .block-header__title {
    font-size: 1.7rem;
    font-weight: 700;
    margin-top: 10px;
    text-align: center;
  }
}