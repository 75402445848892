.block-teammates__item {
    img {
        width: 100%;
        height: 220px;
        object-fit: cover;
        object-position: 50% top;
    }
}

.teammate {
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}

.teammate__info {
    padding: 14px 16px 16px;
    text-align: center;
}

.teammate__position {
    font-size: 14px;
    color: #6c757d;
}

.teammate__name,
.teammate__position {
    line-height: 1.3;
}


@media screen and (max-width: 768px) {
    .block-teammates__item {
        img {
            height: 300px;
        }
    }
}