@media (min-width: 1200px){
  .about__body {
    grid-template-rows: 124px auto auto;
    grid-template-columns: 1fr 380px 730px 1fr;
    grid-template-rows: 100px auto auto;
  }
  .about__image {
    grid-column: 1/5;
    grid-row: 1/3;
  }
  .about__card {
    min-height: 530px;
    grid-column: 2;
    grid-row: 2/4;
  }
  .about__indicators {
    margin-left: 52px;
    margin-top: 40px;
    margin-bottom: 40px;
    grid-column: 3;
    grid-row: 3;
  }
}

.about__body {
  margin-bottom: 60px;
  display: grid;
  z-index: 0;
}

.about__image {
  position: relative;
  overflow: hidden;
  z-index: -1;
}

.about__card {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  border-radius: 2px;
  padding: 32px 36px;
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
}

.about__indicators {
    align-self: center;
}

.about__image-bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position: 50%;
    z-index: -1;
    &::before {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg,rgba(51,51,51,.7),rgba(51,51,51,.4));
  }
}
.about__image-decor {
    position: absolute;
    bottom: -1px;
}

.decor--type--bottom {
    width: 100%;
    position: relative;
    pointer-events: none;
    overflow: hidden;
    height: 31px;
}

.decor {
    display: block;
}

.about__card-title {
    font-size: 40px;
    font-weight: 700;
    padding: 16px 0 40px;
    text-align: center;
    margin-bottom: 0;
}

.about__card-text {
    // text-align: center;
    line-height: 1.75;
}


.about__card-author {
    padding-left: 28px;
        color: #6c757d;
    font-size: 14px;
    position: relative;
    align-self: center;
    margin-top: 16px;
} 

.about__card-signature {
    padding: 40px 0 12px;
    margin-top: auto;
    align-self: center;
}

.about__indicators-body {
    display: -moz-box;
    display: flex;
    flex-wrap: wrap;
    margin: -12px;
}

.about__indicators-item {
    margin: 12px;
    width: calc(33.33333% - 24px);
}


.about__indicators-item-value {
    font-size: 48px;
    font-weight: 700;
    line-height: 1;
}

.about__indicators-item-title {
    color: #6c757d;
    font-size: 15px;
    margin-top: 6px;
    line-height: 18px;
}

.about__image-decor .decor__end, .about__image-decor .decor__start {
    box-shadow: none;
}

.about__image-decor .decor__center, .about__image-decor .decor__end, .about__image-decor .decor__start {
    background: #fafafa;
}

.decor--type--bottom .decor__start {
    transform: skewX(
30deg
);
}

.decor--type--bottom .decor__start {
    border-top-right-radius: 2px;
}

.decor--type--bottom .decor__end, .decor--type--bottom .decor__start {
    height: 21px;
}

.decor--type--bottom .decor__start {
    left: 0;
    transform-origin: right bottom;
}

.decor--type--bottom .decor__end, .decor--type--bottom .decor__start {
    position: absolute;
    width: calc((100% - 1510px)/2);
    bottom: 0;
}

.decor--type--bottom .decor__end {
    border-top-left-radius: 2px;
}

.decor--type--bottom .decor__end {
    transform: skewX(
-30deg
);
}

.decor--type--bottom .decor__end {
    right: 0;
    transform-origin: left bottom;
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .about__body {
    grid-template-columns: 1fr 690px 1fr;
    grid-template-rows: 80px auto 60px auto;
  }
}

.about__image-decor {
  position: absolute;
  bottom: -1px;
}

@media (max-width: 1199.98px) {
  .about__image {
    grid-column: 1/4;
    grid-row: 1/3;
  }
  .about__card {
    padding: 32px 64px;
    margin: 0 auto;
    max-width: 510px;
    grid-column: 2;
    grid-row: 2/4;
  }
  .about__indicators {
    text-align: center;
    margin-top: 48px;
    width: 100%;
    grid-column: 2;
    grid-row: 4;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .about__body {
    grid-template-columns: 1fr 510px 1fr;
    grid-template-rows: 40px auto 60px auto;
  }
}

@media (max-width: 767.98px) {
  .about__card {
    padding: 32px 36px;
  }
  .about__indicators {
    margin-top: 40px;
  }
  .about__indicators-item-value {
    font-size: 36px;
  }
}

@media (max-width: 575.98px) {
  .about__body {
    grid-template-columns: 20px calc(100% - 40px) 20px;
    grid-template-rows: 20px auto 60px auto;
  }
  .about__card {
    padding: 24px 28px;
    font-size: 15px;
  }
  .about__indicators-body {
    margin: -16px;
  }
  .about__indicators-item {
    margin: 16px;
    width: calc(100% - 32px);
  }
}