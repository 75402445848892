@import '../../colors.scss';

.checkOutCard {
  .payment-type {
    margin-top: 50px;
    font-family: SFProDisplay-Bold;
    font-size: 20px;
    line-height: 25px;
    color: #000;
    font-weight: 700;
  }
  .type {
    display: flex;
    margin-top: 25px;
    margin-bottom: 25px;
    .type-shadow {
      border: 2px solid $blue !important;
    }
    .payme, .yandex {
      cursor: pointer;
      width: 155px;
      height: 120px;
      border: 2px solid #d8d8d8;
      .title {
        padding: 18px 0 0 22px;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #000;
      }
      img {
        margin: 20px 0 0 22px;
      }
    }
    .yandex {
      margin-left: 20px;
    }
  }
  .card {
    position: relative;
    display: -moz-box;
    display: flex;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word !important;
    background-clip: border-box;
    .card-body--padding--2 {
      padding: 2rem;
      flex: 1 1 auto;
      min-height: 1px;
      padding: 1.25rem;
      .cart__totals-table {
        width: 100%;
        margin-bottom: 32px;
        font-size: 15px;
        tbody, thead {
          line-height: 20px;
          tr:last-child>* {
            padding-bottom: 10px;
          }
        } 

        tfoot {
          font-size: 20px;
          tr {
            border-top: 1px solid #ebebeb;
          }
          th, td {
            padding-top: 12px;
          }
          th {
            font-weight: 500;
          }
        }          
        td {
          padding: 0;
        }
        & tbody tr>* {
          padding-top: 8px;
        }
      }
    }
  }
}

.checkout__totals-products {
  tr:first-child td {
    padding-top: 16px;
  }
  td {
    padding: 4px 0;
    text-align: left !important;
  }
}

.checkout__totals-header {
  font-size: 13px;
  text-transform: uppercase;
  tr {
    width: 100%;
  } 
  tr {
    padding-bottom: 4px;
    border-bottom: 1px solid #ebebeb;
  }
}

.checkout__totals {
  td, th {
    padding: 0;
  }
  td:last-child, th:last-child {
    padding-left: 20px;
    text-align: right;
  }
}

.creditCard-img img {
  width: 70px;
}
.creditCard {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  background-color: #dadada;
  padding: 15px;
  border-radius: 15px;
  h4 {
    font-size: 1.5rem;
    margin-left: 15px;
  }
}