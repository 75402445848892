#header {
  background: #fff; }
  #header .header {
    background: #fff;
    padding: 15px 0; }
    #header .header .open-button {
      transition: all 0.5s; }
    #header .header .close-button,
    #header .header .open-button {
      cursor: pointer;
      justify-content: center;
      align-items: center; }
      #header .header .close-button svg,
      #header .header .open-button svg {
        width: 25px;
        height: 25px; }
    #header .header .close-button svg {
      fill: red; }
    #header .header .tel-icon svg {
      fill: green; }
    #header .header .open-button svg {
      fill: black; }
    #header .header .header-left {
      color: #262626;
      z-index: 10; }
      #header .header .header-left .menusItem > a,
      #header .header .header-left .menusItem > button {
        font-weight: 500;
        display: block;
        border-radius: 2px;
        color: #04333b;
        white-space: nowrap;
        font-size: 17px;
        background-color: transparent; }
      #header .header .header-left .menusItem > a {
        margin-right: 15px; }
    #header .header .header-right {
      z-index: 10;
      font-size: 24px;
      font-family: 'Arial', sans-serif; }
      #header .header .header-right p {
        font-size: 15px; }

.indicator {
  position: relative; }
  .indicator .indicator__button {
    padding: 10px;
    display: flex;
    position: relative;
    min-width: 54px;
    color: inherit;
    border-radius: 2px;
    background: transparent;
    border: none; }
    .indicator .indicator__button:hover, .indicator .indicator__button:focus {
      background: inherit;
      background-color: #f2f2f2; }
    .indicator .indicator__button .indicator__icon {
      padding: 2px;
      display: block;
      position: relative; }
      .indicator .indicator__button .indicator__icon .like-icon path {
        fill: #1A8194; }
      .indicator .indicator__button .indicator__icon .compare-icon {
        color: #1A8194; }
      .indicator .indicator__button .indicator__icon svg {
        color: #262626;
        width: 32px;
        height: 32px; }
        .indicator .indicator__button .indicator__icon svg path {
          width: 32px;
          height: 32px; }
      .indicator .indicator__button .indicator__icon .indicator__counter {
        position: absolute;
        top: -2px;
        right: -3px;
        font-size: 10px;
        line-height: 1;
        padding: 2px 3px 1px;
        border-radius: 6.5px;
        text-align: center;
        z-index: 0;
        color: #fff;
        font-weight: 500; }
        .indicator .indicator__button .indicator__icon .indicator__counter:before {
          left: 0;
          right: 0;
          -webkit-transform: skewX(-11deg);
                  transform: skewX(-11deg);
          background-color: #1A8194;
          display: block;
          position: absolute;
          content: "";
          top: 0;
          bottom: 0;
          z-index: -1;
          border-radius: 2.5px; }
    .indicator .indicator__button .indicator-text {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      align-content: space-between;
      justify-content: space-between; }
      .indicator .indicator__button .indicator-text .indicator__title {
        display: block;
        white-space: nowrap;
        color: #999;
        padding-bottom: 3px;
        font-size: 13px;
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis; }
      .indicator .indicator__button .indicator-text .indicator__value {
        font-weight: 500;
        font-size: 18px;
        display: block;
        white-space: nowrap; }
  .indicator .indicator__content {
    pointer-events: none;
    right: 0;
    top: 100%;
    position: absolute;
    visibility: hidden;
    -webkit-transform-origin: top;
            transform-origin: top;
    -webkit-transform: rotateX(60deg);
            transform: rotateX(60deg);
    opacity: 0;
    transition: opacity .2s, visibility 0s .2s, -webkit-transform .2s;
    transition: transform .2s, opacity .2s, visibility 0s .2s;
    transition: transform .2s, opacity .2s, visibility 0s .2s, -webkit-transform .2s; }
    .indicator .indicator__content .dropcart {
      display: block;
      width: 320px;
      background-color: #fff;
      box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
      border-radius: 1.5px;
      padding: 24px;
      color: #262626; }
      .indicator .indicator__content .dropcart .dropcart__list {
        list-style: none;
        padding: 0;
        margin: -8px 0 0; }
        .indicator .indicator__content .dropcart .dropcart__list .dropcart__item {
          display: flex; }
          .indicator .indicator__content .dropcart .dropcart__list .dropcart__item .dropcart__item-image {
            flex-shrink: 0;
            width: 70px; }
            .indicator .indicator__content .dropcart .dropcart__list .dropcart__item .dropcart__item-image .image__body {
              padding-bottom: 100%;
              display: block;
              position: relative;
              width: 100%; }
              .indicator .indicator__content .dropcart .dropcart__list .dropcart__item .dropcart__item-image .image__body .image__tag {
                display: block;
                position: absolute;
                width: 100%;
                height: 60px;
                object-fit: cover; }
          .indicator .indicator__content .dropcart .dropcart__list .dropcart__item .dropcart__item-info {
            padding: 4px 0 5px;
            flex-grow: 1;
            margin: 0 10px; }
            .indicator .indicator__content .dropcart .dropcart__list .dropcart__item .dropcart__item-info .dropcart__item-name {
              font-size: 15px;
              line-height: 18px; }
              .indicator .indicator__content .dropcart .dropcart__list .dropcart__item .dropcart__item-info .dropcart__item-name a {
                color: inherit;
                transition: color .2s; }
            .indicator .indicator__content .dropcart .dropcart__list .dropcart__item .dropcart__item-info .dropcart__item-meta {
              display: flex;
              align-items: center;
              margin-top: 4px; }
              .indicator .indicator__content .dropcart .dropcart__list .dropcart__item .dropcart__item-info .dropcart__item-meta .dropcart__item-quantity {
                padding-left: 6px;
                margin-right: -2.82352px;
                color: #fff;
                font-weight: 500;
                position: relative;
                height: 16px;
                z-index: 0;
                font-size: 11px;
                padding: 1px 10.82352px 0; }
                .indicator .indicator__content .dropcart .dropcart__list .dropcart__item .dropcart__item-info .dropcart__item-meta .dropcart__item-quantity:before {
                  position: absolute;
                  left: 0;
                  background: #1A8194;
                  -webkit-transform: skewX(-20deg);
                          transform: skewX(-20deg);
                  -webkit-transform-origin: left bottom;
                          transform-origin: left bottom;
                  display: block;
                  content: "";
                  width: calc(100% - 5.82352px);
                  height: 100%;
                  top: 0;
                  z-index: -1;
                  border-radius: 2.5px 3px; }
              .indicator .indicator__content .dropcart .dropcart__list .dropcart__item .dropcart__item-info .dropcart__item-meta .dropcart__item-price {
                color: #262626;
                font-weight: 500;
                position: relative;
                height: 16px;
                z-index: 0;
                font-size: 11px;
                padding: 3px 10.82352px 0; }
                .indicator .indicator__content .dropcart .dropcart__list .dropcart__item .dropcart__item-info .dropcart__item-meta .dropcart__item-price:before {
                  left: 0;
                  background: #1A8194;
                  -webkit-transform: skewX(-20deg);
                          transform: skewX(-20deg);
                  position: absolute;
                  display: block;
                  content: "";
                  width: calc(100% - 5.82352px);
                  height: 100%;
                  top: 0;
                  z-index: -1;
                  border-radius: 2.5px 3px;
                  background: #ebebeb; }
          .indicator .indicator__content .dropcart .dropcart__list .dropcart__item .dropcart__item-remove {
            margin-right: -13px;
            border-radius: 13px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            border: none;
            fill: currentColor;
            background-color: #fff;
            color: #ccc;
            position: relative;
            flex-shrink: 0;
            width: 26px;
            height: 26px; }
            .indicator .indicator__content .dropcart .dropcart__list .dropcart__item .dropcart__item-remove:hover {
              background-color: #f2f2f2;
              color: #999; }
            .indicator .indicator__content .dropcart .dropcart__list .dropcart__item .dropcart__item-remove svg {
              display: block;
              overflow: hidden; }
        .indicator .indicator__content .dropcart .dropcart__list .dropcart__divider {
          height: 1px;
          background: #ebebeb;
          margin: 8px 0; }
      .indicator .indicator__content .dropcart .dropcart__actions {
        display: flex; }
        .indicator .indicator__content .dropcart .dropcart__actions .btn-secondary {
          border-color: #f0f0f0;
          background: #f0f0f0;
          color: #333;
          flex-grow: 1; }
        .indicator .indicator__content .dropcart .dropcart__actions .btn-primary {
          border-color: #1A8194;
          background: #1A8194;
          color: #fff;
          flex-grow: 1;
          margin-left: 8px; }
      .indicator .indicator__content .dropcart .dropcart__empty {
        text-align: center;
        font-size: 15px;
        padding: 20px 0; }
    .indicator .indicator__content .account-menu {
      display: block;
      width: 280px;
      background-color: #fff;
      box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
      border-radius: 1.5px;
      color: #262626; }
      .indicator .indicator__content .account-menu .account-menu__form {
        padding: 0 30px 32px; }
        .indicator .indicator__content .account-menu .account-menu__form .account-menu__form-title {
          text-align: center;
          padding: 32px 0 26px;
          font-weight: 500; }
        .indicator .indicator__content .account-menu .account-menu__form .form-group {
          margin-bottom: 1rem; }
          .indicator .indicator__content .account-menu .account-menu__form .form-group .sr-only {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px; }
          .indicator .indicator__content .account-menu .account-menu__form .form-group .account-menu__form-forgot {
            position: relative; }
          .indicator .indicator__content .account-menu .account-menu__form .form-group .form-control-sm {
            height: 30px;
            padding: 5.5px 8px;
            font-size: 14px;
            line-height: 17px; }
          .indicator .indicator__content .account-menu .account-menu__form .form-group .form-control {
            border-radius: 2px;
            background-clip: border-box;
            -webkit-appearance: none;
            appearance: none;
            transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, background .15s ease-in-out, opacity .15s ease-in-out;
            color: #262626;
            background-color: #ebebeb;
            border: 1px solid #ebebeb;
            display: block;
            width: 100%; }
          .indicator .indicator__content .account-menu .account-menu__form .form-group .account-menu__form-forgot-link {
            right: 5px;
            position: absolute;
            top: 5px;
            bottom: 5px;
            border-radius: 2px;
            font-size: 12px;
            background: fff;
            color: #6c757d;
            display: flex;
            align-items: center;
            padding: 0 7px;
            transition: background .1s, color .1s; }
            .indicator .indicator__content .account-menu .account-menu__form .form-group .account-menu__form-forgot-link:hover {
              background: #333;
              color: #fff; }
        .indicator .indicator__content .account-menu .account-menu__form .account-menu__form-button {
          margin-top: 32px;
          text-align: center; }
          .indicator .indicator__content .account-menu .account-menu__form .account-menu__form-button .btn {
            padding-left: 28px;
            padding-right: 28px; }
            .indicator .indicator__content .account-menu .account-menu__form .account-menu__form-button .btn:hover {
              background: #333;
              color: #fff; }
          .indicator .indicator__content .account-menu .account-menu__form .account-menu__form-button .btn-primary {
            border-color: #1A8194;
            background: #1A8194;
            color: #fff; }
        .indicator .indicator__content .account-menu .account-menu__form .account-menu__form-link {
          font-size: 14px;
          text-align: center; }
          .indicator .indicator__content .account-menu .account-menu__form .account-menu__form-link a {
            color: #6c757d;
            transition: color .1s; }
            .indicator .indicator__content .account-menu .account-menu__form .account-menu__form-link a:hover {
              color: inherit; }
      .indicator .indicator__content .account-menu .account-menu__user {
        display: flex;
        align-items: center;
        padding: 14px 20px;
        color: inherit; }
        .indicator .indicator__content .account-menu .account-menu__user .account-menu__user-avatar {
          margin-right: 14px;
          flex-shrink: 0; }
          .indicator .indicator__content .account-menu .account-menu__user .account-menu__user-avatar img {
            object-fit: cover;
            width: 45px;
            height: 45px;
            border-radius: 100%;
            max-width: 100%; }
        .indicator .indicator__content .account-menu .account-menu__user .account-menu__user-info {
          display: flex;
          flex-direction: column;
          justify-content: center; }
          .indicator .indicator__content .account-menu .account-menu__user .account-menu__user-info .account-menu__user-name {
            font-size: 15px;
            line-height: 20px; }
          .indicator .indicator__content .account-menu .account-menu__user .account-menu__user-info .account-menu__user-email {
            font-size: 14px;
            line-height: 18px;
            color: #6c757d;
            margin-top: 1px;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 180px;
            height: 1.2em;
            white-space: nowrap; }
      .indicator .indicator__content .account-menu .account-menu__divider {
        height: 1px;
        background: #ebebeb; }
      .indicator .indicator__content .account-menu .account-menu__links {
        list-style: none;
        padding: 12px 0;
        margin: 0; }
        .indicator .indicator__content .account-menu .account-menu__links .active {
          color: black !important; }
          .indicator .indicator__content .account-menu .account-menu__links .active svg {
            color: black !important; }
        .indicator .indicator__content .account-menu .account-menu__links a,
        .indicator .indicator__content .account-menu .account-menu__links button {
          display: block;
          color: #6c757d;
          font-size: 15px;
          font-family: inherit;
          line-height: inherit;
          padding: 7px 20px;
          border: none;
          width: 100%;
          background: transparent;
          font-weight: 600; }
        .indicator .indicator__content .account-menu .account-menu__links button {
          text-align: start; }

.indicator--open .indicator__content {
  pointer-events: auto;
  z-index: 3;
  transition-delay: 0s, 0s, 0s;
  opacity: 1;
  visibility: visible;
  -webkit-transform: rotateX(0deg);
          transform: rotateX(0deg); }

.common-search-box {
  position: static !important; }
  .common-search-box .indicator__content {
    width: 100%; }

.citiesMenus {
  position: relative; }
  .citiesMenus .cityDisticts {
    position: absolute;
    background-color: white;
    left: 200px;
    display: none;
    top: 0;
    border: 1px solid black; }

.uz-map {
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-left: 10px;
  border: 1px solid black !important;
  padding: 5px !important; }
  .uz-map svg {
    width: 25px;
    height: 25px; }

.background-colors {
  background: linear-gradient(to right, #1A8194 50%, #333 50%); }

.header-logo {
  flex: 20% 1;
  max-width: 20%; }
  .header-logo span,
  .header-logo .logo {
    font-size: 30px;
    font-weight: 800; }
    .header-logo span:first-child,
    .header-logo .logo:first-child {
      color: #1A8194; }
    .header-logo span:last-child,
    .header-logo .logo:last-child {
      color: #404040; }

@media screen and (min-width: 1200px) {
  #header .header .header-left,
  #header .header .header-right {
    flex: 40% 1;
    max-width: 40%; } }

@media screen and (max-width: 992px) {
  #header .header {
    box-shadow: 0 1px 6px 0 black;
    height: 60px;
    padding: 0 15px;
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    left: 0;
    padding-top: 12px;
    padding-bottom: 12px; }
    #header .header .header-right .indicator .indicator__button {
      padding: 0;
      min-width: 25px;
      margin-left: 10px; }
      #header .header .header-right .indicator .indicator__button .indicator__icon .indicator__counter {
        position: absolute;
        top: 0px;
        right: -5px;
        font-size: 8px; }
      #header .header .header-right .indicator .indicator__button .indicator__icon svg {
        color: #262626;
        width: 25px;
        height: 25px; }
        #header .header .header-right .indicator .indicator__button .indicator__icon svg path {
          width: 25px;
          height: 25px; }
    #header .header .header-logo {
      flex: 1 1;
      display: flex;
      justify-content: center; } }

.menusItem {
  display: flex;
  justify-content: center;
  align-items: center; }
  .menusItem .active {
    color: #1A8194 !important; }

.citiesMenus {
  width: 200px;
  list-style-type: none;
  background-color: #fff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2); }
  .citiesMenus a {
    padding: 10px;
    display: block;
    color: black;
    font-weight: 500; }

.cityDisticts {
  list-style-type: none;
  background-color: #fff; }
  .cityDisticts a {
    padding: 10px;
    display: block;
    color: black;
    font-weight: 500; }

.headerMenus .indicator__content {
  left: 0; }

@media screen and (max-width: 576px) {
  .close-button svg,
  .open-button svg {
    width: 27px !important;
    height: 27px !important; }
  .header-logo span {
    font-size: 30px !important; } }

@media screen and (max-width: 500px) {
  #header .header {
    height: 70px;
    display: flex;
    align-items: center; }
    #header .header > div {
      flex: 1 1; }
      #header .header > div .close-button svg,
      #header .header > div .open-button svg {
        width: 30px !important;
        height: 35px !important; }
      #header .header > div .indicator__icon svg {
        color: #262626;
        width: 30px !important;
        height: 30px !important; }
        #header .header > div .indicator__icon svg path {
          width: 30px !important;
          height: 30px !important; }
      #header .header > div .indicator__icon .indicator__counter {
        padding: 2px 4px 2px;
        font-size: 10px !important; }
    #header .header .header-logo {
      flex: 1 1;
      justify-content: center; }
      #header .header .header-logo span {
        font-size: 35px !important; }
    #header .header .header-right .indicator .indicator__button {
      margin-left: 5px; }
  .filter__img h4 {
    display: none; } }
