.conveniences {
  padding-top: 20px;

  header {
    .separator_off {
      font-size: 35px;
      margin-bottom: 10px !important;
      text-transform: uppercase;
    }

    h6 {
      letter-spacing: .01em;
      line-height: 1.5;
      font-weight: 400;
      margin: 0;
      color: inherit;
      margin-bottom: 30px;
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 576px) {
  .conveniences {
    header {
      padding-right: 0;
      padding-left: 0;

      .separator_off {
        font-size: 27px;
        text-transform: uppercase;
      }

      h6 {
        letter-spacing: .01em;
        line-height: 1 !important;
        margin-bottom: 30px;
        font-size: 18px;
      }
    }

  }
}