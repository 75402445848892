.hospitals-in-map {
  width: 140px;
  height: 40px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    z-index: 2;
    padding: 2px 10px;
  }

  img {
    object-fit: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.3;
    width: 100%;
    height: 100%;
  }
}

.mapModal {
  max-width: 100% !important;
  margin: 0rem;
  height: 90vh;
  margin: 0;

  .modal-dialog {
    max-width: 100%;
    margin: 1.75rem auto;
    padding: 1.5rem;
  }

  .modal-content {
    border: 0px solid rgba(0, 0, 0, 0.2);
    border-radius: 0rem;
    width: 90%;
    margin: auto;
    margin-top: 5vh;

    .modal-body {
      height: 90vh;
      padding: 0;
    }
  }

  .map-modal-close {
    background-color: none;
    position: absolute;
    right: -15px;
    top: -15px;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 20%);
    cursor: pointer;
    z-index: 5;

    svg {
      width: 30px;
      height: 30px;
    }
  }
}

@media (min-width: 576px) {
  .mapModal {
    margin: 0 !important;
  }
}

@media screen and (max-width: 992px) {
  .clinicsComp {
    .image {
      width: 100%;
    }

    .clinic-card__image .image__tag {
      width: 100% !important;
    }
  }
}

@media screen and (max-width: 576px) {
  .mapModal {
    max-width: 100% !important;
    margin: 0rem;
    height: 90vh;
    margin: 0;

    .modal-content {
      width: 100%;
    }

    .map-modal-close {
      border-radius: 0 !important;
      box-shadow: none !important;
      right: 0px;
      top: 0px;
      cursor: pointer;
      z-index: 5;

      svg {
        width: 35px;
        height: 35px;
      }
    }
  }
}