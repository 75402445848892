@import '../../colors';

.timetablecard {
  display: inline-block;
  font: bold 12px/38px Arial;
  cursor: pointer;
  width: 57px;
  text-align: center;
  margin: 5px;
  color: #999999;
  outline: 2px solid $blue;
  position: relative;
  div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: green;
    svg {
      width: 30px; 
      height: 30px;
    }
  }
}
