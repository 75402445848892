@import '../colors';

@media (min-width: 1200px) {
  .post-header {
    padding-top: 24px;
  }
}

.post-header {
  position: relative;
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  z-index: 0;
  overflow: hidden;
}

.post-header__body {
  max-width: 620px;
  margin: 0 auto;
  text-align: center;
  padding: 52px 30px;
}

.post-header--has-image {
  background: #262626;
  margin-bottom: -150px;
  padding-bottom: 150px;
  .post-header__body {
    color: #fff;
    padding: 60px 30px;
    .post-header__categories {
      margin-bottom: 28px;
    }
    .post-header__title {
      margin: 0;
      color: white;
      font-size: 36px;
      font-weight: 700;
    }
  }
  .post-header__meta {
    color: inherit;
  }
  .post-header__meta {
    font-size: 14px;
    margin-top: 24px;
    color: #999;
  }
  .post-header__meta {
    color: inherit;
  }
  .post-header__meta-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: -moz-box;
    display: flex;
    flex-wrap: wrap;
    -moz-box-pack: center;
    justify-content: center;
    .post-header__meta-link {
      color: inherit;
      transition: color .1s;
    }
    .post-header__meta-item+.post-header__meta-item {
      margin-left: 24px;
      position: relative;
      &:before {
        left: -14px;
        display: block;
        position: absolute;
        content: "";
        width: 4px;
        height: 4px;
        border-radius: 2px;
        background: currentColor;
        top: 7px;
      }
    }
  }
.post-header__decor {
  position: absolute;
  bottom: -1px;
}

.decor--type--bottom {
  width: 100%;
  // position: relative;
  pointer-events: none;
  overflow: hidden;
  height: 31px;
}

.decor {
  display: block;
}

  .post-header--has-image .post-header__meta, .post-header--has-image .post-header__title {
    text-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
  }
  .post-header__categories-list {
    list-style: none;
    margin: -3px;
    padding: 0;
    display: -moz-box;
    display: flex;
    -moz-box-pack: center;
    justify-content: center;
    .post-header__categories-item {
      margin: 3px;
    }
    .post-header__categories-link {
      display: block;
      font-size: 10px;
      text-transform: uppercase;
      background: $blue;
      padding: 3px 9px;
      border-radius: 1.5px;
      font-weight: 500;
    }
  }
}


.post-header__image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: 50%;
  z-index: -1;
  opacity: .22;
  background-image: url("../static/images/doctors-background.jpg");
}

.post-header__decor {
  position: absolute;
  bottom: -1px;
}

.decor--type--bottom {
  width: 100%;
  position: relative;
  pointer-events: none;
  overflow: hidden;
  height: 31px;
}

.post-view {
  background-color: #FAFAFA;
  padding-bottom: 50px;
}

.post-view__body {
  display: -moz-box;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
}

.post-view__item-post {
  -moz-box-flex: 1;
  flex-grow: 1;
  max-width: 760px;
}

.post-view__card {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  position: relative;
}

.post__body {
  padding: 36px;
}

.typography {
  line-height: 1.625;
  p {
    margin-bottom: 1.5em;
  }
}

.section-heading {
    display: table;
    width: 100%;
    height: 3px;
    padding-bottom: 10px;
    color: #3c4245;
    table-layout: fixed;
    font-size: 18px;
}

.sf-body p {
    font-family: Arial,Helvetica,sans-serif;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: normal;
    font-style: normal;
    font-stretch: normal;
}


@media (max-width: 1199.98px) {
  .post-header {
    padding-top: 0;
  }
}

@media (max-width: 991.98px) {
  .post-view__body {
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
  }
}
@media (max-width: 419px) {
  .post-header--has-image {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .post-view__card {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media (max-width: 767.98px) {
  .post__body {
    padding: 30px;
  }
  .section-heading {
    margin: 30px 0 20px;
  }
}

@media (max-width: 575.98px){
  .post-header__title {
    font-size: 28px !important;
  }
  .post__body {
    padding: 20px;
  }
  .typography {
      font-size: 15px;
      line-height: 1.75;
  }
}
