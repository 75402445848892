@import '../colors.scss';

.card-body--padding--2 {
  padding: 2rem !important;
}

.loginComp {
  margin-top: 50px;
  .card-body {
    -moz-box-flex: 1;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
  }
}

.form-group {
  margin-bottom: 1rem;
}

label {
  margin-bottom: .25rem;
  font-size: 15px;
}

.card-title {
  font-size: 24px !important;
  font-weight: 500 !important;
  padding-bottom: 8px !important;
  border-bottom: 1px solid #ebebeb !important;
  margin-bottom: 2rem !important;
  margin-top: -4px !important;
}

.form-check {
  padding-left: 1.5rem;
}

.form-check-input {
  margin-left: -1.5rem;
  position: absolute;
  margin-top: .1875rem !important;
  .input-check__body {
    display: block;
    position: relative;
    width: 16px;
    height: 16px;
    .input-check__input {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      cursor: inherit;
    }
    .input-check__input:checked~.input-check__box {
      background: $blue;
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05);
    }
    .input-check__input:checked~.input-check__icon {
      fill: #fff;
      transition-delay: 0s,0s,0s;
      transform: scale(1);
    }
    .input-check__box {
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 1.2px;
      background: #fff;
      box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);
      transition: background .15s,box-shadow .15s;
    }
    .input-check__icon {
      pointer-events: none;
      position: absolute;
      left: 4px;
      top: -2px;
      bottom: 0;
      fill: #fff;
      transform: scale(0);
      transition: fill .15s,transform .15s,visibility 0s .15s;
    }
  }
}

@media (min-width: 992px) {
  .container--max--lg {
      max-width: 960px  !important;
  }
}