@import '../../colors.scss';

.pretty.p-default input:checked~.state label:after {
  background-color: $blue !important;
}

.pretty {
  margin-bottom: 5px;

  .state label:before,
  .state label:after {
    top: 4px !important;
  }
}

.clinicServiceCard {
  .clinic-card {
    display: -moz-box;
    display: flex;
    position: relative;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);

    .image--type--clinic {
      position: relative;

      .like-img {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        border: none;
        background: transparent;
        transform: scale(1);
        transition: 0.5s ease;

        &:focus {
          svg {
            transform: scale(1.1);
          }
        }
      }
    }

    .clinic-card__image {
      .image__tag {
        display: block;
        width: 200px;
        height: 200px;
        object-fit: cover;
        margin: auto;
      }
    }

    .clinic-card__info {
      padding-left: 0;
      padding-right: 20px;
      padding-top: 16px;
      padding-bottom: 20px;
      -moz-box-flex: 1;
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      .clinic-card__name {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;

        a {
          color: inherit;
          text-decoration: none;
          background-color: transparent;
        }

        .service_name {
          font-weight: 600;
        }
      }

      .clinic-card__features {
        font-size: 14px;
        padding-top: 10px;
        color: #6c757d;
        line-height: 21px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;

        ul {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            padding-left: 12px;
            position: relative;

            &:before {
              display: block;
              position: absolute;
              content: "";
              width: 3px;
              height: 3px;
              border-radius: 1.5px;
              background: currentColor;
              top: 9px;
              opacity: .7;
              left: 2px;
            }
          }
        }
      }
    }

    .clinic-card__footer {
      border-left: 1px solid #ebebeb;
      width: 220px;
      -moz-box-align: center;
      align-items: center;
      display: -moz-box;
      display: flex;
      -moz-box-ordinal-group: 3;
      order: 2;
      flex-shrink: 0;
      -moz-box-orient: vertical;
      -moz-box-direction: normal;
      flex-direction: column;
      padding: 16px 20px;
      justify-content: space-between;
    }
  }

  .product-card__compare {
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 10px 5px;
    position: relative;
    border: none;
    background: transparent;
    color: #262626;
    fill: #d9d9d9;
    border-radius: 2px;
    -webkit-transition: background .15s, fill .15s;
    transition: background .15s, fill .15s;

    &:hover {
      background: #f5f5f5;
      fill: #bfbfbf;
    }

    svg {
      margin-right: 8px;
    }
  }
}

.clinic-name {
  bottom: 0;
  position: absolute;
  background-color: #333;
  padding: 7px 10px;
  width: 100%;
  color: white;
  text-align: center;
  border-radius: 20px 20px 0 0;
}

@media screen and (min-width: 768px) {
  .clinicServiceCard {
    .clinic-card {
      .clinic-card__image {
        padding: 16px 24px;
        width: 260px;
      }

      .clinic-card__footer {
        .clinic-card__prices {
          font-size: 20px;
          display: -moz-box;
          display: flex;
          -moz-box-align: end;
          align-items: flex-end;
          flex-wrap: wrap;
        }

        .clinic-card__prices {
          font-weight: 500;
          letter-spacing: -.04em;
          line-height: 1;
          color: #262626;
          font-size: 20px;
          margin-bottom: 15px;
        }

        .clinic-card__addtocart-full {
          display: block;
          height: 36px;
          padding: 0 20px;
          width: 100%;
          position: relative;
          background: $blue;
          color: #fff;
          border: none;
          border-radius: 2px;
          font-weight: 500;
          transition: background-color .15s, color .15s;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .clinicServiceCard {
    .clinic-card {
      -moz-box-orient: vertical;
      -moz-box-direction: normal;
      flex-direction: column;

      .clinic-card__image {
        padding: 20px;
        width: 100%;
        display: -moz-box;
        display: flex;
        position: relative;

        .image {
          width: 100%;
        }

        .image__tag {
          display: block;
          width: 100%;
          height: 200px;
          object-fit: cover;
        }
      }

      .clinic-card__info {
        padding: 0 20px 20px;

        .clinic-card__features {
          padding-top: 12px;
        }
      }

      .clinic-card__footer {
        width: 100%;
        border-top: 1px solid #ebebeb;
        -moz-box-orient: horizontal;
        -moz-box-direction: normal;
        padding: 20px;

        .clinic-card__prices {
          padding-bottom: 16px;
          display: -moz-box;
          display: flex;
          -moz-box-align: end;
          align-items: flex-end;
          flex-wrap: wrap;
        }

        .clinic-card__addtocart-full {
          height: 30px;
          padding: 0 16px;
          font-size: 15px;
          position: relative;
          background: $blue;
          color: #fff;
          border: none;
          border-radius: 2px;
          font-weight: 500;
          transition: background-color .15s, color .15s;
        }
      }
    }
  }
}