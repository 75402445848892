@import '../colors';

.cartComp {
  .ant-tabs-nav-list {
    justify-content: space-between;
    width: 100%;
  }
  .ant-tabs-tab-btn {
    text-align: center !important;
  }
  .ant-tabs-tab {
    flex: 1 !important;
    display: block;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 0px !important;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
    border-bottom-color: #f0f0f0;
    border: 1px solid $blue;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations{
    display: none;
  }
}



.cartPayment {
  display: flex;
  padding-bottom: 50px;
  .cart__table {
    -moz-box-flex: 1;
    flex-grow: 1;
  }
  .cart__totals {
    margin-left: 50px;
    width: 360px;
    flex-shrink: 0;
  }
}

@media screen and (min-width: 992px) {
  .cartPayment {
    .cart-table {
      align-self: flex-start;
      background-color: #fff;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    }
    .cart__totals {
      margin-left: auto;
    }
  }
}

@media screen and (max-width: 1399.98px) {
  .cartPayment {
    flex-wrap: wrap;  
    .cart__table {
      width: 100%;
    }
    .cart__totals {
      margin-top: 40px;
      width: 400px;
    }
  }
}

@media screen and (min-width: 992px) {
  .cartPayment {
    .cart-table {
      align-self: flex-start;
      background-color: #fff;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    }
    .cart__totals {
      margin-left: auto;
    }
  }
}

@media screen and (max-width: 992px) {
  .cartPayment {
    .cart__totals {
      margin-left: 0;
    }
  }
}

.block-empty__body {
    text-align: center;
    padding: 50px 0;
}

.block-empty__title {
    margin-top: 12px;
    font-size: 36px;
    font-weight: 700;
}

.block-empty__action {
    margin-top: 32px;
}

// cart products table

.cart-table__table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  .cart-table__head {
    font-size: 13px;
    text-transform: uppercase;
  }
  .cart-table__column--image {
    width: 1px;
    text-align: center;
    .image__body {
      display: block;
      position: relative;
      width: 100%;
      padding-bottom: 100%;
    }
  }
  .cart-table__column--product {
    line-height: 1.25;
    .cart-table__product-name {
      color: inherit;
      transition: color .15s;
      &:hover {
        color: #007bff;
        text-decoration: none;
      }
    }
  }
  .cart-table__column--price {
    text-align: right;
    width: 130px;
  }
  .cart-table__column--quantity {
    padding-left: 36px;
    width: 150px;
    text-align: center;
  }
  .cart-table__column--total {
    text-align: right;
    width: 130px;
  }
  .cart-table__column--remove {
    padding-left: 0;
    width: 1px;
    white-space: nowrap;
    .btn-group-sm >.btn-icon.btn, .btn-sm.btn-icon {
      height: 30px;
      width: 30px;
      font-size: .875rem;
      line-height: 1.5;
    }
    .btn-muted, .btn-muted.disabled, .btn-muted:disabled {
      border-color: #fff;
      background: #fff;
      color: #ccc;
      &:hover {
        border-color: #f2f2f2;
        background: #f2f2f2;
        color: #4d4d4d;
      }
    }
    .btn-icon {
      padding: 0;
      svg {
        display: block;
        margin: auto;
      }
    }

  }
  .form-row {
    display: -moz-box;
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
  }
}

@media (min-width: 992px) {
  .cart-table__table {
    .cart-table__column--image {
      .image {
        width: 80px;
        text-align: center;
        .image__tag {
          display: block;
          position: absolute;
          width: 100%;
          height: 80px;
          object-fit: cover;
        }
      }
    }
    .cart-table__column {
      padding: 14px 16px;
      border-bottom: 1px solid #ebebeb;
      &:first-child {
        padding-left: 28px;
      }
      &:last-child {
        padding-right: 28px;
      }
    }
    .cart-table__remove {
      margin-right: -12px;
    }
    .cart-table__foot {
      td {
        padding: 20px 24px;
        .cart-table__actions {
          display: -moz-box;
          display: flex;
          -moz-box-pack: justify;
          justify-content: flex-end;
          .cart-table__coupon-form {
            width: 360px;
          }
        }
      }
    }
  }
}
  

@media screen and (max-width: 1399.98px) {
  .cart {
    flex-wrap: wrap;
    .cart__table {
      width: 100%;
    }
  }
}

@media screen and (max-width: 991.98px) {
  .cart-table__table {
    display: block;
    .cart-table__head {
      display: none;
    }
    .cart-table__body {
      display: -moz-box;
      display: flex;
      flex-wrap: wrap;
      margin: -8px;
      .cart-table__row {
        background-color: #fff;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
        display: -moz-box;
        display: flex;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        flex-direction: column;
        position: relative;
        margin: 8px;
        width: calc(33.33333% - 16px);
        .cart-table__column--image {
          text-align: center;
          padding: 16px 14px !important;
          width: 100%;
          .image {
            max-width: 220px;
            margin: 0 auto;
          }
        }
        .cart-table__column {
          display: block;
          padding: 0;
        }
        .cart-table__column--product {
          -moz-box-flex: 1;
          flex-grow: 1;
          text-align: center;
          padding: 0 14px 16px;
        }
        .cart-table__column--price, .cart-table__column--quantity, .cart-table__column--total, .cart-table__column--time {
          padding-left: 14px;
          width: 100%;
          display: -moz-box;
          display: flex !important;
          -moz-box-align: center;
          align-items: center;
          -moz-box-pack: justify;
          justify-content: space-between;
          border-top: 1px solid #ebebeb;
          padding: 10px 14px !important;
          font-size: 15px;
          &:before {
            margin-right: 12px;
            text-align: left;
            content: attr(data-title) ":";
            display: block;
            width: calc(40% - 6px);
            flex-shrink: 0;
            font-weight: 500;
          }
        }
      }
    }
    .cart-table__foot, .cart-table__foot td, .cart-table__foot tr {
      display: block;
      padding: 0;
    }
    .cart-table__foot {
      td {
        .cart-table__actions {
          background-color: #fff;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
          margin-top: 20px;
          display: -moz-box;
          display: flex;
          -moz-box-pack: justify;
          justify-content: space-between;
          .cart-table__coupon-form, .cart-table__update-button {
            padding: 16px;
          } 
       }
      }
    }
    .cart-table__remove {
      right: 0;
      position: absolute;
      top: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .cart-table__table {
    .cart-table__body {
      .cart-table__row {
        width: calc(50% - 16px);
      }
    }
    .cart-table__foot {
      .cart-table__actions {
        display: block !important;
        .cart-table__update-button {
          border-top: 1px solid #ebebeb !important;
          text-align: center !important;
          display: block;
        }
      }
    } 
  }
}

@media (max-width: 474px) {
  .cart-table__table {
    .cart-table__body {
      .cart-table__row {
        width: 100% !important;
      }
    }
  }
}

@media screen and (max-width: 575.98px) {
  .card-body--padding--2 {
    padding: 1.5rem;
  }
}

@media (max-width: 767.98px) {
  .cart__totals {
    width: 100% !important;
  }
}

.input-number {
  display: block;
  width: 100%;
  position: relative;
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  .input-number__input {
    -moz-appearance: textfield;
    display: block;
    width: 100%;
    min-width: 88px;
    text-align: center;
  }
  .form-control {
    border-radius: 2px;
    background-clip: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,background .15s ease-in-out,opacity .15s ease-in-out;
    border: 1px solid #ebebeb;
    height: 36px;
    color: #262626;
    background-color: #ebebeb;
    padding: 7.5px 10px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    &:hover {
      border-color: #d9d9d9;
    } 
    &:focus {
      box-shadow: none;
      background-color: #fff;
      border-color: #d9d9d9;
      outline: 0;
      color: #495057;
    }
  }
  .input-number__add, .input-number__sub {
    position: absolute;
    height: 100%;
    width: 24px;
    top: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    opacity: .3;
    transition: opacity .18s;
  }
  .input-number__add:hover, .input-number__sub:hover {
    opacity: 1;
  }
  .input-number__add {
    right: 1px;
    &:after {
      width: 2px;
      height: 8px;
    }
  }
  .input-number__sub {
    left: 1px;
  }
  .input-number__add:before, .input-number__sub:before {
    width: 8px;
    height: 2px;
  }
  .input-number__add:after, .input-number__add:before, .input-number__sub:after, .input-number__sub:before {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: currentColor;
  }
}


// Order-card 

.orderCard {
  .card {
    position: relative;
    display: -moz-box;
    display: flex;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word !important;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125) !important;
    border-radius: .25rem !important;
    .card-body--padding--2 {
      padding: 2rem;
      flex: 1 1 auto;
      min-height: 1px;
      padding: 1.25rem;
      .cart__totals-table {
        width: 100%;
        margin-bottom: 32px;
        font-size: 15px;
        tbody, thead {
          line-height: 20px;
          tr:last-child>* {
            padding-bottom: 10px;
          }
        } 
        tfoot {
          font-size: 20px;
          tr {
            border-top: 1px solid #ebebeb;
          }
          th, td {
            padding-top: 12px;
          }
          th {
            font-weight: 500;
          }
        }          

        tr:after {
          display: block;
          content: "";
          clear: both;
        }
        th {
          float: left;
          padding: 0;
        }
        td {
          text-align: right;
          padding: 0;
        }
        & tbody tr>* {
          padding-top: 8px;
        }
      }
    }
  }
}

