@import '../../_colors.scss';

.storageProfileCard {
  .ant-card-head-title {
    font-size: 30px;
    padding: 6px 0;
  }
  .ant-card-head-title, .size {
    text-align: center;
  }
  .size {
    font-size: 25px;
  }
}
.activeStorageProfileCard {
  transform: scale(1.04);
  .ant-card-head {
    background-color: $blue;
    color: white;
  }
}