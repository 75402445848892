@import '../../colors.scss';

.clinicCard {
  .clinic-card {
    display: -moz-box;
    display: flex;
    position: relative;
    width: 100%;

    .clinic-card__image {
      .image__tag {
        display: block;
        width: 200px;
        height: 150px;
        object-fit: contain;
      }
    }

    .clinic-card__info {
      padding-left: 0;
      padding-right: 20px;
      padding-top: 16px;
      padding-bottom: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;

      .clinic-card__name {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;

        a {
          color: inherit;
          text-decoration: none;
          background-color: transparent;
        }
      }

      .clinic-card__features {
        font-size: 14px;
        color: #6c757d;
        line-height: 21px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;

        ul {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            padding-left: 12px;
            position: relative;

            &:before {
              display: block;
              position: absolute;
              content: "";
              width: 3px;
              height: 3px;
              border-radius: 1.5px;
              background: currentColor;
              top: 9px;
              opacity: .7;
              left: 2px;
            }
          }
        }
      }
    }
  }
}


@media screen and (min-width: 768px) {
  .clinicCard {
    .clinic-card {
      .clinic-card__image {
        padding: 16px 24px;
        width: 260px;
      }

      .clinic-card__footer {
        border-left: 1px solid #ebebeb;
        width: 220px;
        -moz-box-align: center;
        align-items: center;
        display: -moz-box;
        display: flex;
        -moz-box-ordinal-group: 3;
        order: 2;
        flex-shrink: 0;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        flex-direction: column;
        padding: 20px;

        .clinic-card__prices {
          padding: 28px 0;
          font-size: 20px;
          display: -moz-box;
          display: flex;
          -moz-box-align: end;
          align-items: flex-end;
          flex-wrap: wrap;
          -moz-box-pack: center;
          justify-content: center;
        }

        .clinic-card__addtocart-full {
          display: block;
          height: 36px;
          padding: 0 20px;
          width: 100%;
          margin-bottom: 10px;
          position: relative;
          background: $blue;
          color: #fff;
          border: none;
          border-radius: 2px;
          font-weight: 500;
          transition: background-color .15s, color .15s;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .clinicCard {
    .clinic-card {
      -moz-box-orient: vertical;
      -moz-box-direction: normal;
      flex-direction: column;

      .clinic-card__image {
        padding: 20px;
        width: 100%;
        display: -moz-box;
        display: flex;
        position: relative
      }

      .clinic-card__info {
        padding: 0 20px 20px;

        .clinic-card__features {
          padding-top: 12px;
        }

        .clinic-location {
          margin-top: 15px;
        }
      }

      .clinic-card__footer {
        border-top: 1px solid #ebebeb;
        -moz-box-orient: horizontal;
        -moz-box-direction: normal;
        flex-direction: row;
        flex-wrap: wrap;
        -moz-box-align: center;
        align-items: center;
        padding: 20px;

        .clinic-card__prices {
          padding-top: 20px;
          padding-bottom: 16px;
          display: -moz-box;
          display: flex;
          -moz-box-align: end;
          align-items: flex-end;
          flex-wrap: wrap;
        }

        .clinic-card__addtocart-full {
          height: 30px;
          padding: 0 16px;
          font-size: 15px;
          position: relative;
          background: $blue;
          color: #fff;
          border: none;
          border-radius: 2px;
          font-weight: 500;
          transition: background-color .15s, color .15s;
        }
      }
    }
  }
}

.stage,
.distance {
  font-size: 13px;
  color: #41464a;
  text-align: center;
  padding: 5px 5px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}