.searchHospital {
  .searchForms {
    flex: 25%;
    background: #f9f9f9;
    padding: 8px;
    height: 90vh;
    overflow-y: auto;
  }
  
  .hospitals {
    flex: 30%;
    background: #f9f9f9;
    padding: 8px;
    height: 90vh;
    overflow-y: auto;
  }
  
  .hospitalLink {
    z-index: 4;
    box-sizing: border-box;
    display: block;
    position: relative;
    width: 100%;
    background: #fff;
    border-radius: 3px;
    border: 1px solid #d8d8d8;
    // border-bottom: 0;
    cursor: pointer;
    margin-bottom: 12px;
    text-decoration: none;
    &:hover {
      .hospitalCard {
        .hos-content {
          h3 {
            color: rgb(243, 229, 38);
          }
        }
      }
    }
  }
  
  .hospitalsMap {
    flex: 45%;
    &>div {
      height: 90vh !important;
    }
    overflow: hidden;
    .jfCaJ {
      .hoverHospital {
        display: none;
        position: absolute;
        width: 300px;
        top: 28px;
      }
      .hotel-marker__pin {
        svg {
          fill: red;
          stroke: #fff;
          stroke-width: 1px;
          pointer-events: none;
          z-index: 1;
          &:hover {
            fill: blue;
          }
        }
      }
      &:hover {
        .hotel-marker__pin {
          svg {
            fill: blue;
          }
        }
        // .hoverHospital {
        //   display: block;
        // }
        // width: 40px;
        height: 40px;
      } 
    }
    .hotel-marker__shadow {
      fill: #000;
      fill-opacity: .54;
      z-index: 1;
      // position: absolute;
      display: block;
      margin: auto;
      width: 12px;
      height: 4px;
      // top: 33px;
      // left: 8px;
    }
    .hotel-marker {
      width: 28px;
      // height: 34px;
      position: relative;
      pointer-events: none;
    }
    .hotel-marker__icon {
      width: 8px;
      height: 8px;
      background-color: #fff;
      border: 1px solid #fff;
      border-radius: 50%;
      position: absolute;
      top: 10px;
      left: 10px;
      pointer-events: none;
      z-index: 2;
    }
    & > div > div {
      width: 100% !important;
      height: 100% !important;
    }
  }
}


@keyframes locationAnimation {
  0% {
    height: 34px;
  }
  50% {
    height: 50px;
  }
  100% {
    height: 34px;
  }
}


@media screen and (max-width: 992px) {
  .searchHospital {
    .searchForms {
      flex: 45%;
      height: 70vh;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background: #f1f1f1; 
      }
      &::-webkit-scrollbar-thumb {
        background: #888; 
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555; 
      }
    }
    .hospitals {
      flex: 55%;
      height: 70vh;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background: #f1f1f1; 
      }
      &::-webkit-scrollbar-thumb {
        background: #888; 
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555; 
      }
    }
    .hospitalsMap {
      margin-top: 20px;
      flex: 100%;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background: #f1f1f1; 
      }
      &::-webkit-scrollbar-thumb {
        background: #888; 
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555; 
      }
    }
  }
}

.searchFormComp{
  form {
    background: #fff;
    border-radius: 3px;
    border: 1px solid #bdbdbd;
    margin: 0 0 10px;
    .filter_by_wrapper {
      padding: 8px;
      border-bottom: 1px solid #bdbdbd;
      h2 {
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
      }
    }
    .form-group {
      margin-bottom: 1rem;
      padding: 0 8px 8px;
      margin-bottom: 8px;
      border-bottom: 1px solid #bdbdbd;
      legend {
        margin: 0;
        padding: 4px 0;
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
      }
      label {
        font-size: 13px;
        color: #000;
        margin-left: 10px;
        &:before {
          content: '';
          position: absolute;
          left: none;
          top: 0;
          height: 20px;
          width: 20px;
          left: -35px;
        }
        &:after {
          height: 20px;
          width: 20px;
          top: 0px;
          left: -2.2rem;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .searchFormComp{
    form {
      background: #fff;
      border-radius: 3px;
      border: 1px solid #bdbdbd;
      margin: 0 0 10px;
      .filter_by_wrapper {
        padding: 8px;
        border-bottom: 1px solid #bdbdbd;
        h2 {
          font-size: 12px;
          line-height: 12px;
          font-weight: 700;
        }
      }
      .form-group {
        margin-bottom: 1rem;
        padding: 0 8px 8px;
        margin-bottom: 8px;
        border-bottom: 1px solid #bdbdbd;
        legend {
          margin: 0;
          padding: 4px 0;
          font-size: 12px;
          line-height: 20px;
          font-weight: 700;
        }
        label {
          font-size: 10px;
          color: #000;
          &:before {
            content: '';
            position: absolute;
            left: none;
            top: 0;
            height: 15px;
            width: 15px;
          }
          &:after {
            height: 15px;
            width: 15px;
            top: 0px;
            left: none;
            // left: 2px;
          }
        }
      }
    }
  }
}