.illnessComp {
  h3 {
  padding: 20px 0 0 0;
  }
  .illnessFilter {
    background: linear-gradient(to top,#fcfcfc,#fff);
    box-shadow: 0 1px 2px 0 rgba(203, 203, 203, 50%);
    margin: 30px 0 40px;
    border-radius: 10px;
  }
  .searchIllness {
    background-color: white;
    border-bottom: 1px solid #ededed;
    input {
      border: none;
      font-family: 'Arial',sans-serif;
      outline: none;
      font-size: 16px;
      letter-spacing: -.2px;
      color: #333;
      border: 0;
      width: 100%;
      height: 58px;
      &:focus {
        outline: none;
        border: none;
      }
    }
  }
}
.uzbletters {
  background-color: white;
  padding: 17px;
  a {
    text-transform: uppercase;
    font-family: 'Arial',sans-serif;
    font-size: 16px;
    letter-spacing: -.2px;
    color: #333;
    display: block;
  }
}

@media screen and (max-width: 992px) {
  .illnessComp {
    h1 {
      font: 18px/21px Arial,sans-serif !important;
      margin-bottom: 15px !important;
    }
  }
  .uzbletters {
    white-space: nowrap;
    overflow: auto;
    display: block;
    &::-webkit-scrollbar {
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1; 
    }
    &::-webkit-scrollbar-thumb {
      background: #888; 
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555; 
    }
    a {
      width: 30px;
      display: inline-block;
    }
  }
}