.likeComp {
  .cart__table {
    -moz-box-flex: 1;
    flex-grow: 1;
  }
  .cart__totals {
    margin-left: 50px;
    width: 360px;
    flex-shrink: 0;
  }
  .cart-table__table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    .cart-table__head {
      font-size: 13px;
      text-transform: uppercase;
    }
    .cart-table__column--image {
      width: 1px;
      text-align: center;
      .image__body {
        display: block;
        position: relative;
        width: 100%;
        padding-bottom: 100%;
        .image__tag {
          display: block;
          position: absolute;
          width: 100%;
          height: 100% !important;
          object-fit: cover;
        }
      }
    }
    .cart-table__column--product {
      line-height: 1.25;
      .cart-table__product-name {
        color: inherit;
        transition: color .15s;
        &:hover {
          color: #007bff;
          text-decoration: none;
        }
      }
    }
    .cart-table__column--price {
      text-align: right;
      width: 130px;
    }
    .cart-table__column--remove {
      padding-left: 0;
      width: 1px;
      white-space: nowrap;
      .btn-group-sm >.btn-icon.btn, .btn-sm.btn-icon {
        height: 30px;
        width: 30px;
        font-size: .875rem;
        line-height: 1.5;
      }
      .btn-muted, .btn-muted.disabled, .btn-muted:disabled {
        border-color: #fff;
        background: #fff;
        color: #ccc;
        &:hover {
          border-color: #f2f2f2;
          background: #f2f2f2;
          color: #4d4d4d;
        }
      }
      .btn-icon {
        padding: 0;
        svg {
          display: block;
          margin: auto;
        }
      }
  
    }
    .form-row {
      display: -moz-box;
      display: flex;
      flex-wrap: wrap;
      margin-right: -5px;
      margin-left: -5px;
    }
  }
}

@media (min-width: 768px) {
  .wishlist__column--button {
      white-space: nowrap;
      width: 200px;
      text-align: right;
  }

}

@media (min-width: 992px) {
  .likeComp {
    .btn {
      margin-right: 30px;
    }
    .cart-table {
      align-self: flex-start;
      background-color: #fff;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    }
    .cart__totals {
      margin-left: auto;
    }
  }
  .cart-table__table {
    .cart-table__column--image {
      .image {  
        width: 80px;
        text-align: center;
      }
    }
    .cart-table__column {
      padding: 14px 16px;
      border-bottom: 1px solid #ebebeb;
      &:first-child {
        padding-left: 28px;
      }
      &:last-child {
        padding-right: 28px;
      }
    }
    .cart-table__remove {
      margin-right: -12px;
    }
  }
}


@media screen and (max-width: 1399.98px) {
  .cart {
    flex-wrap: wrap;
    .cart__table {
      width: 100%;
    }
  }
}

@media screen and (max-width: 991.98px) {
  .cart-table__table {
    display: block;
    .wishlist__column--button {
      display: flex !important;
      justify-content: center;
      margin-bottom: 15px;
    }
    .cart-table__head {
      display: none;
    }
    .cart-table__body {
      display: -moz-box;
      display: flex;
      flex-wrap: wrap;
      margin: -8px;
      .cart-table__row {
        background-color: #fff;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
        display: -moz-box;
        display: flex;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        flex-direction: column;
        position: relative;
        margin: 8px;
        width: calc(33.33333% - 16px);
        .cart-table__column--image {
          text-align: center;
          padding: 16px 14px !important;
          width: 100%;
          .image {
            max-width: 220px;
            margin: 0 auto;
          }
        }
        .cart-table__column {
          display: block;
          padding: 0;
        }
        .cart-table__column--product {
          -moz-box-flex: 1;
          flex-grow: 1;
          text-align: center;
          padding: 0 14px 16px;
        }
      }
    }
    .cart-table__remove {
      right: 0;
      position: absolute;
      top: 0;
    }
  }
}


@media screen and (max-width: 768px) {
  .cart-table__table {
    .cart-table__body {
      .cart-table__row {
        width: calc(50% - 16px);
      }
    } 
  }
}

@media (max-width: 474px) {
  .cart-table__table {
    .cart-table__body {
      .cart-table__row {
        width: 100% !important;
      }
    }
  }
}

@media screen and (max-width: 575.98px) {
  .card-body--padding--2 {
    padding: 1.5rem;
  }
}
