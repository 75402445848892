@import '../../colors.scss';

.breadcrumb__item-link {
  pointer-events: auto;
  position: absolute;
  background-color: #ebebeb;
  top: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 3px;
  -webkit-transition: background-color .1s;
  transition: background-color .1s;
}

.directionTop {
  background-color: transparent !important;
  padding: 0.75rem 0 !important;
  margin-bottom: 0 !important;
  top: -35px;

  span:last-child {
    svg {
      display: none;
    }

    a {
      pointer-events: none;
    }
  }

  span {
    margin-right: 15px;
    color: gray;
    font-size: 12px;
    line-height: 16px;
    margin-top: 4px;
    margin-right: -7.12435px;
    float: left;

    &:first-child a {
      &:after {
        left: 0;
        right: 12.12435px;
        content: "";
        @extend .breadcrumb__item-link
      }

      &:hover:after {
        background-color: #e5e5e5;
      }
    }

    a {
      color: $blue;
      padding-right: 27px;
      padding-left: 17px;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 0;
      height: 21px;
      pointer-events: none;

      &:hover:before {
        background-color: #e5e5e5;
      }

      &:before {
        // &:hover {
        //   color: rgb(50, 50, 50);
        // }
        left: 6.062175px;
        right: 6.062175px;
        -webkit-transform: skewX(-30deg);
        transform: skewX(-30deg);
        content: "";
        @extend .breadcrumb__item-link
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .directionTop {
    a {
      color: $blue;
      padding-right: 10px !important;
      padding-left: 10px !important;
      &:before {
        left: 6.062175px;
        right: 6.062175px;
        -webkit-transform: skewX(-15deg) !important;
        transform: skewX(-15deg) !important;
        border-radius: 1px !important;
      }
    }
    span {
      margin-right: -9.12435px;
    }
  }
}