@import '../../colors.scss';

.react-tabs__tab-list {
   font-size: 20px;
   display: flex;
   justify-content: center;
   border-bottom: 2px solid white !important;
}

.submenus-selection > div > div{
   height: auto;
   min-height: 50px;
}

.css-1uccc91-singleValue {
   display: flex;
   align-items: center;
   color: #333 !important;
   height: 100%;
} 
.css-yk16xz-control {
   background-color: transparent !important;
   border-radius: 0 !important;
   &:hover {
      border-color: $blue !important;
   }
}

.css-1wa3eu0-placeholder {
   color: black !important; 
}

.btn {
   border-radius: 0 !important;
   overflow-y: none !important;
}

.css-1pahdxg-control {
   border-radius: 0;
   background-color: transparent !important;
   border-radius: 0 !important;
   border-width: 1px;
   border-color: $blue !important;
   box-shadow: 0 0 0 1px $blue !important;
   outline: 0!important;
}

.react-select-container {
   background-color: red im !important;
}

.css-26l3qy-menu {
   background-color: rgba(37,44,65,.8) !important;
   color: white !important;
   overflow-y: hidden !important;
   padding-top: 0 !important;
}


.searchButton {
   width: 100%;
   color: white;
   border-radius: 0px;
   border: none;
   height: 50px;
   font-size: 20px !important;
   background-size: 42px 45px;
   // margin-left: 20px;
}

@media screen and (max-width: 992px) {
   .searchButton {
      margin-left: 0;
      margin-top: 20px;
   }
}