@import '../../../colors.scss';

.topheader {
  display: flex;
  justify-content: space-between;
  color: #262626;
  background: #fff;
  .header__topbar-end-bg, .header__topbar-start-bg {
    flex: 50%;
    max-width: 50%;
  }
  .topbar {
    display: -moz-box;
    display: flex;
    height: 100%;
    font-size: 14px;
    line-height: 1;
  }
  .header__topbar-start-bg {
    .topbar--spaceship-start {
      background: $blue;
    }
    .topbar__item-text {
      display: -moz-box;
      display: flex;
      -moz-box-align: center;
      align-items: center;
      padding: 10px 20px 10px 0;
      .topbar__link {
        color: white;
        font-weight: 400;
        transition: color .12s;
        &:hover {
          color: white;
        }
      }
    }
  }
  .middle-header {
    position: absolute;
    top: 0;
    left: calc(50% - 110px);
    .header__topbar-end-bg-2 {
      border-bottom-left-radius: 2.5px;
      transform: skewX(-30deg);
      transform-origin: top left;
    }
    .header__topbar-start-bg-2 {
      border-bottom-right-radius: 2.5px;
      transform: skewX(30deg);
      transform-origin: top right;
    }
    .header__logo {
      z-index: 5 !important;
    }
    .header__logo, .header__topbar-end-bg-2, .header__topbar-start-bg-2 {
      position: absolute;
      top: 0;
      width: 220px;
      z-index: 4;
      height: 100px;
      background-color: white;
      display: -moz-box;
      display: flex;
      -moz-box-align: stretch;
      align-items: stretch;
      justify-content: center;
    }
    .logo {
      display: -moz-box;
      display: flex;
      -moz-box-orient: vertical;
      -moz-box-direction: normal;
      flex-direction: column;
    }
    .logo__slogan {
      text-align: center;
      height: 34px;
      color: #999;
      font-weight: 500;
      padding-top: 11px;
      font-size: 11px;
      line-height: 1;
      text-transform: uppercase;
    }
    .logo__image {
      -moz-box-pack: center;
      justify-content: center;
      -moz-box-flex: 1;
      flex-grow: 1;
      display: -moz-box;
      display: flex;
      -moz-box-align: center;
      align-items: center;
      height: 50px;
      font-size: 30px;
      font-weight: 800;
      span:first-child {
        color: $blue;
      }
      span:last-child {
        color: #404040;
      }
    }
  }
  .header__topbar-end-bg {
    background: #333;
    .topbar--spaceship-end {
      -moz-box-pack: end;
      justify-content: flex-end;
      display: flex;
      .topbar__menu {
        position: relative;
        .topbar__button {
          display: -moz-box;
          display: flex;
          -moz-box-align: center;
          align-items: center;
          position: relative;
          background: transparent;
          font-family: inherit;
          border: none;
          padding: 0 10px;
          transition: background .12s;
          height: 100%;
        }
        .topbar__button-label {
          color: #9e9e9e;
          font-weight: 400;
          margin-right: 3px;
        }
        .topbar__button-title {
          color: #fff;
          font-weight: 500;
        }
        .topbar__button-arrow {
          color: grey;
          right: 0px;
          position: absolute;
          fill: currentColor;
        }
        .topbar__menu-body {
          z-index: 11;
          visibility: hidden;
          transform-origin: top;
          transform: rotateX(60deg);
          opacity: 0;
          transition: transform .2s,opacity .2s,visibility 0s .2s;
          color: #262626;
          background-color: #fff;
          box-shadow: 0 1px 15px rgba(0, 0, 0, 10%), 0 1px 3px rgba(0, 0, 0, 0.10);
          position: absolute;
          width: 150px;
          padding: .5rem 0;
          font-size: 14px;
          line-height: 26px;
          right: 0;
          font-weight: 500;
        }
      }
      .topbar__menu--open {
        .topbar__menu-body {
          z-index: 11;
          visibility: visible;
          transition-delay: 0s,0s,0s;
          opacity: 1;
          transform: rotateX(0deg);
        }
      }
    }
  }
}

.header__search {
  position: absolute;
  align-self: flex-end;
  justify-self: center;
  bottom: -24px;
  width: 480px;
  top: 100px;
  z-index: 7;
  left: -110px;
}

.search__body {
  position: relative;
  display: -moz-box;
  display: flex;
  z-index: 1;
  height: 44px;
  background-color: transparent;
}

.search__shadow {
  left: 10px;
  position: absolute;
  bottom: 100%;
  height: 10px;
  width: calc(100% - 20px);
  overflow: hidden;
  &:before {
    display: block;
    content: "";
    width: 100%;
    height: 60px;
    position: relative;
    top: 100%;
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.12), 0 -1px rgba(0, 0, 0, 0.02);
    border-radius: 120px/10px;
  }
}

.search__input {
  width: 0;
  flex-shrink: 1;
  -moz-box-flex: 1;
  flex-grow: 1;
  background: transparent;
  border: none;
  padding: 0 12px;
  text-align: center;
  font-size: 15px;
  margin: 6px 0;
  z-index: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #262626;
  &::-webkit-input-placeholder {
    margin-bottom: 50px;
    text-indent: 0;
    font-size: 12px;
    transition: text-indent 0.2s 0.2s ease; 
  }
  &:focus {
    outline: none;
    &::-webkit-input-placeholder {
      text-indent: 200%;
    }
  }
}

.search__input:hover~.search__box {
  opacity: 1;
  color: #f0f0f0;
}

.search__button {
  margin: 5px 7px;
  width: 72px;
  padding: 0;
  border: none;
  background: transparent;
  display: -moz-box;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
  fill: currentColor;
  z-index: 1;
  pointer-events: none;
  position: relative;
  color: rgba(0,0,0,.3);
  &:before {
    left: -8px;
    transform: skewX(-30deg);
    position: absolute;
    display: block;
    content: "";
    width: 3px;
    top: 0;
    bottom: 0;
    background: #ebebeb;
    z-index: -1;
    border-radius: 1.5px;
  }
  &:after {
    left: 0;
    transform: skewX(-30deg);
    position: absolute;
    display: block;
    content: "";
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: auto;
    z-index: -1;
    border-radius: 3px;
    transition: background .12s;
    background: transparent;
  }
}

.search__button--hover:after, .search__button:hover:after {
    background: #f0f0f0;
}

.search__button--start {
  -moz-box-ordinal-group: 0;
  order: -1;
}

.search__button-title {
  display: none;
}

.search__button--start:after {
    transform: skewX(30deg);
}

.search__button--start:before {
  left: auto;
  right: -8px;
  transform: skewX(30deg);
}

.search__box {
  left: 92px;
  right: 92px;
  position: absolute;
  top: calc((100% - 34px)/2);
  height: 34px;
  transition: color .2s,opacity .2s;
  opacity: 0;
  color: #f0f0f0;
  &:before {
    left: 0;
    transform: skewX(30deg);
  }
  &:after {
    right: 0;
    transform: skewX(-30deg);
  }
  &:after, &:before {
    position: absolute;
    content: "";
    display: block;
    background: currentColor;
    height: 100%;
    width: 80%;
    top: 0;
    border-radius: 3px;
  }
}

.search__decor {
  left: -10px;
  position: absolute;
  display: -moz-box;
  display: flex;
  bottom: -10px;
  top: 19px;
  height: 34px;
  width: calc(100% + 20px);
  z-index: -1;
}

.search__decor-end, .search__decor-start {
  overflow: hidden;
  height: 100%;
  -moz-box-flex: 1;
  flex-grow: 1;
  padding: 0 10px;
}

.search__decor-start:before {
    content: "";
    display: block;
    height: 24px;
    background-color: #fff;
    border-bottom-left-radius: 2px;
    margin-right: -20px;
    transform: skewX(30deg);
    transform-origin: left top;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}

.search__decor-end:before {
    border-bottom-right-radius: 2px;
    margin-left: -20px;
    transform: skewX(-30deg);
    transform-origin: right top;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    content: "";
    display: block;
    height: 24px;
}

.language-box {
  display: block;
  padding: 3px 1rem;
  background: transparent;
  color: #262626;
  font-family: inherit;
  font-weight: inherit;
  border: none;
  width: 100%; 
  text-align: start;
  display: flex;
  img {
    margin-right: 10px;
  }
}

.search__dropdown {
  top: -webkit-calc(100% + 10px);
  top: -moz-calc(100% + 10px);
  top: calc(100% + 10px);
}

.search_suggestions {
  padding: 14px 0;
}

.search__dropdown {
  left: 14px;
  right: 14px;
  color: #262626;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 100%;
  border-radius: 1.5px;
  opacity: 0;
  -webkit-transform: translateY(30px);
  -moz-transform: translateY(30px);
  transform: translateY(30px);
  visibility: hidden;
  -webkit-transition: opacity .25s cubic-bezier(.645,.045,.355,1),visibility 0s .25s,-webkit-transform .25s cubic-bezier(.645,.045,.355,1);
  transition: opacity .25s cubic-bezier(.645,.045,.355,1),visibility 0s .25s,-webkit-transform .25s cubic-bezier(.645,.045,.355,1);
  -moz-transition: opacity .25s cubic-bezier(.645,.045,.355,1),transform .25s cubic-bezier(.645,.045,.355,1),visibility 0s .25s,-moz-transform .25s cubic-bezier(.645,.045,.355,1);
  transition: opacity .25s cubic-bezier(.645,.045,.355,1),transform .25s cubic-bezier(.645,.045,.355,1),visibility 0s .25s;
  transition: opacity .25s cubic-bezier(.645,.045,.355,1),transform .25s cubic-bezier(.645,.045,.355,1),visibility 0s .25s,-webkit-transform .25s cubic-bezier(.645,.045,.355,1),-moz-transform .25s cubic-bezier(.645,.045,.355,1);
}

.search__dropdown--open {
  opacity: 1 !important;
  -webkit-transform: translateY(5px) !important;
  -moz-transform: translateY(5px) !important;
  transform: translateY(5px) !important;
  visibility: visible !important;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
}


.suggestions__group-title {
  padding: 3px 20px;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  color: #999;
}

.suggestions__product {
  display: -moz-box;
  display: flex;
  padding: 6px 20px;
  color: inherit;
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .header__search {
    width: 440px;
  }
}

@media screen and (max-width: 576px) {
  .topheader {
    .header__topbar-end-bg {
      .topbar--spaceship-end {
        padding-right: 10px;
      }
    }
  }
}