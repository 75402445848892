@import '../../colors';

.categories-list__item {
  padding: 1.125rem;
  text-align: center;
  position: relative;
  &:before {
    position: absolute;
    display: block;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0,0,0,.03);
    pointer-events: none;
    opacity: 0;
    transition: opacity .12s;
    z-index: 1;
  }
  &:hover:before {
    opacity: 1;
  }
  a {
    display: block;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    color: inherit;
  }
  .image {
    width: 100%;
    max-width: 100px;
    margin: 10px auto;
    background-color: $blue;
  }
}

.image--type--category .image__body, .image--type--product .image__body {
  padding-bottom: 100%;
}

.image--type--category .image__body, .image--type--product .image__body {
  display: block;
  position: relative;
  width: 100%;
}

.image--type--category .image__tag, .image--type--product .image__tag {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}

.categories-list__item-products {
  margin-top: 4px;
  font-size: 14px;
  color: #6c757d;
}

.categories-list__item-name {
  height: 40px;
}

.categories-list__divider {
    width: 1px;
    background-color: #ebebeb;
}

@media (min-width: 1200px) {
  .categories-list__item {
    width: calc((100% - 6px)/6);
  }
  .categories-list__divider:nth-child(12n) {
    width: 100%;
    height: 1px;
  }
}

@media (min-width: 1000px) and (max-width: 1200px) {
  .categories-list__item {
    width: calc((100% - 5px)/5);
  }
  .categories-list__divider:nth-child(10n) {
    width: 100%;
    height: 1px;
  }
}

@media (min-width: 800px) and (max-width: 1000px) {
  .categories-list__item {
    width: calc((100% - 4px)/4);
  }
  .categories-list__divider:nth-child(8n) {
    width: 100%;
    height: 1px;
  }
}

@media (min-width: 576px) and (max-width: 800px) {
  .categories-list__item {
    width: calc((100% - 3px)/3);
  }
  .categories-list__divider:nth-child(6n) {
    width: 100%;
    height: 1px;
  }
}

@media (max-width: 575.98px) {
  .categories-list__item {
    width: calc((100% - 2px)/2);
  }
  .categories-list__divider:nth-child(4n) {
    width: 100%;
    height: 1px;
  }
}