.widget__header {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #ebebeb;
  cursor: pointer;
  img {
    width: 20px;
    height: 20px;
  }
}

.widget {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}

.widget-filters__item {
  padding: 8px 1.5rem;
  border-bottom: 1px solid #ebebeb;
  .filter--opened {
    .filter__body {
      transition-delay: 0s,0s;
      visibility: visible;
      height: auto;
      opacity: 1;
    }
    .filter__title {
      .filter__arrow {
        transform: rotate(180deg);
      }
    }
  }
  .filter__title {
    display: block;
    border: none;
    padding: 6px 10px;
    cursor: pointer;
    width: calc(100% + 20px);
    position: relative;
    color: inherit;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    border-radius: 2px;
    margin: 0 -10px;
    transition: background-color .2s,fill .2s;
    background: transparent;
    fill: #ccc;
    text-align: left;
    &:focus {
      outline: none;
    }
    &:focus, &:hover {
      transition-duration: .1s,.1s;
      background: #f2f2f2;
      fill: #b3b3b3;
    }
    .filter__arrow {
      right: 10px;
      position: absolute;
      top: 11px;
      transition: transform .2s;
      svg {
        display: block;
      }
    }
  }      
  .filter__body {
    margin: 0 -1.5rem;
    overflow: hidden;
    visibility: hidden;
    height: 0;
    opacity: 0;
    transition: height .2s,opacity .2s,visibility 0s .2s;
    .filter__container {
      padding: 25px 1.5rem;
      .filter-category__list {
        list-style: none;
        padding: 0;
        margin: 0;
        font-size: 15px;
        .filter-category__item {
          padding: 5px 0;
          display: -moz-box;
          display: flex;
          & > a {
            padding-right: 10px;
            flex-grow: 1;
            color: inherit;
            transition: color .15s;
            &:hover {
              color: #007bff;
            }
          }
        }
      }
    }
  }
}


