$scale: 1.05;

.convenienceCard2 {
  img {
    transform: scale(1, 1);
    transition: all 0.25s;
  }

  h4,
  p {
    opacity: 0.6;
    cursor: pointer;
    transition: all 0.25s;
  }

  &:hover {
    img {
      transform: scale($scale * 1, $scale * 1);
    }

    h4,
    p {
      opacity: 0.9;
    }

    h4 {
      font-size: #{(24 * $scale)}px;
    }

    p {
      font-size: #{(14 * $scale)}px;
    }
  }
}