.techCard {
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);

}

.tech-img {
  height: 200px;
  padding: 15px 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.tech-info {
  padding: 5px 0;
  p {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
}

@media screen and (max-width: 768px) {
  .tech-info {
    padding: 15px;
  }
}