@import '../../../colors.scss';

.footer {
  position: relative;
  background-color: #333;
  color: #9e9e9e;
}

.site-footer__widgets {
  padding: 56px 0 50px;
}

.footer-contacts {
  display: block;
}

.footer-contacts__title,
.footer-links__title,
.footer-newsletter__title {
  font-size: 20px;
  color: #fff;
  margin-bottom: 26px;
  font-weight: 500;
}

.footer-contacts__contacts {
  font-size: 14px;
  line-height: 20px;
  list-style: none;
  padding: 0;
  display: -moz-box;
  display: flex;
  flex-wrap: wrap;

  dt {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: .04em;
    text-transform: uppercase;
    margin-bottom: 2px;
  }

  dd {
    color: #fff;
    margin: 0;
    font-weight: 500;
  }
}

.footer-contacts__text {
  line-height: 22px;
  list-style: none;
  margin: 0;
  font-size: 15px;
}

.footer-links {
  display: block;
}

.footer-links__list {
  font-size: 15px;
  line-height: 20px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links__item {
  padding: 5px 0;
}

.footer-links__list a {
  color: inherit;
  transition: .15s;
}

.footer-newsletter__text {
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 14px;
}

.footer-newsletter__text--social {
  margin-top: 5px;
  margin-bottom: 0;
}

.footer-newsletter__form {
  display: -moz-box;
  display: flex;
  max-width: 380px;
}

.footer-newsletter__form-input {
  flex-shrink: 1;
  border-radius: 2px;
  font-size: 15px;
  height: 38px;
  padding: 0 12px;
  flex-basis: 0;
  min-width: 0;
  -moz-box-flex: 1;
  flex-grow: 1;
  font-family: inherit;
  background-clip: padding-box;
  transition: border .2s, background .2s;
  color: #fff;
  background-color: #4c4c4c;
  border: 2px solid #4c4c4c;

  &:focus {
    outline: none;
    background-color: transparent;
    border-color: hsla(0, 0%, 100%, .16);
  }
}

.footer-newsletter__form-button {
  margin-left: 8px;
  flex-shrink: 0;
  border-radius: 2px;
  border: none;
  padding: 0 20px;
  font-family: inherit;
  font-size: 15px;
  transition: background .2s, color .2s;
  background-color: $blue;
  color: #fff;
}

.footer-newsletter__social-links {
  margin-top: 8px;
}

.social-links__list {
  display: -moz-box;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: -4px;
}

.social-links__item {
  margin: 4px;

  &>a {
    font-size: 16px;
    display: -moz-box;
    display: flex;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: center;
    justify-content: center;
    color: inherit;
    width: 36px;
    height: 36px;
    border-radius: 18px;
    transition: all .2s;

    &:hover {
      color: #fff;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.social-links__item--facebook a {
  background: #3c5a99;
  color: #fff;
}

.social-links__item--youtube a {
  background: #e52e2e;
  color: #fff;
}

.social-links__item--twitter a {
  background: #00a2e8;
  color: #fff;
}

.social-links__item--instagram a {
  background: #815dc7;
  color: #fff;
}

.site-footer__bottom {
  background-color: #2b2b2b;
  font-size: 14px;
  color: #9e9e9e;
  font-weight: 400;

  & a {
    color: #fff;
    font-weight: 500;
    margin-left: 5px;

    &:hover {
      color: white;
    }
  }
}

.site-footer__bottom-row {
  display: -moz-box;
  display: flex;
  height: 72px;
  -moz-box-pack: justify;
  justify-content: space-between;
  -moz-box-align: center;
  align-items: center;
}

@media (min-width: 1200px) {
  .col-xl-4 {
    -moz-box-flex: 0;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-xl-2 {
    -moz-box-flex: 0;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .footer-contacts__contacts {
    margin: 5px -15px 0;

    dl {
      width: calc(50% - 31px);
      margin: 0 15px;
    }

    dl:nth-child(n+3) {
      margin-top: 18px;
    }
  }
}

@media (max-width: 1199.98px) {
  .footer-contacts {
    text-align: center;
    margin-bottom: 42px;
  }

  .footer-contacts__contacts {
    padding-top: 28px;
  }

  .footer-contacts__contacts dl {
    background: hsla(0, 0%, 100%, .05);
    padding: 16px 12px;
    border-radius: 3px;
    display: -moz-box;
    display: flex;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
    -moz-box-pack: center;
    justify-content: center;
  }
}

@media (max-width: 991.98px) {
  .footer-contacts__contacts {
    margin: -6px;
  }

  .footer-contacts__contacts dl {
    width: calc(50% - 12px);
    margin: 6px;
    padding: 20px 16px;
  }
}

@media (max-width: 767.98px) {
  .footer-newsletter {
    text-align: center;
    margin-top: 28px;
  }

  .footer-newsletter__social-links {
    margin-top: 12px;
    display: -moz-box;
    display: flex;
    -moz-box-pack: center;
    justify-content: center;
  }

  .footer-newsletter__form {
    margin: 0 auto;
  }

  .site-footer__bottom-row {
    height: auto;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
    padding: 20px 0 24px;
  }

  .site-footer__payments {
    margin-top: 12px;
  }
}

@media (max-width: 479px) {
  .footer-contacts__contacts dl {
    width: calc(100% - 8px);
    margin: 4px;
  }
}