@import '../colors.scss';

.specificClinicComp {
  .ant-tabs-content {

    .spec,
    .analogs-table,
    .clinic-description {
      padding: 20px;
      height: 354px;
      overflow-y: auto;
    }
  }

  .ant-tabs-nav {
    margin-bottom: 0;
  }

  .clinic-about {
    .ant-tabs-tabpane:nth-child(4) {
      div {
        width: 100% !important;
        height: 354px !important;
      }
    }

    .ant-tabs-nav {
      margin: 0;
    }
  }

  .clinic-head {
    .clinic-head__title {
      font-weight: 700;
      font-size: 32px;
      margin-bottom: 10px;
      color: rgba(0, 0, 0, .87);
    }
  }

  .new-card_about-our-patient__statistic {
    color: rgba(0, 0, 0, .8);
    font-size: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    .material-icons {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      -webkit-font-smoothing: antialiased;
      margin-right: 8px;
    }
  }

  .clinic-rating {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 24px;
    max-width: 328px;
    cursor: pointer;

    .clinic-rating__title {
      font-weight: 700;
      font-size: 16px;
      color: rgba(0, 0, 0, .87);
      margin-bottom: 3px;
    }

    .clinic-rating__subtitle {
      font-size: 13px;
      line-height: 17px;
      color: rgba(0, 0, 0, .6);
      -webkit-text-decoration-line: underline;
      text-decoration-line: underline;
    }

    .clinic-rating__box-val {
      position: relative;
      height: 48px;
      width: 48px;
      min-width: 48px;
      margin-right: 16px;

      .clinic-rating__val {
        position: absolute;
        width: 100%;
        font-style: normal;
        font-weight: 700;
        font-size: 19px;
        line-height: 48px;
        text-align: center;
        color: rgba(0, 0, 0, .87);

        &:before {
          content: "";
          border: 4px solid #f5f5f5;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          border-radius: 100%;
        }
      }

      canvas {
        position: relative;
      }
    }
  }

  .clinic-recommendation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 20px 16px 20px 0px;
    border-top: 1px solid #dfe1e5;
    border-bottom: 1px solid #dfe1e5;
  }

  .clinic-awards {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 20px 16px 20px 0px;
    border-bottom: 1px solid #dfe1e5;
  }

  .clinic-statistics {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 24px;
    overflow-x: auto;

    .clinic-statistics__item {
      min-width: -webkit-max-content;
      min-width: -moz-max-content;
      min-width: max-content;
      max-width: 150px;
      height: 60px;
      border: 1px solid #dfe1e5;
      border-radius: 8px;
      padding: 12px 16px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      text-align: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      margin: 4px;

      .clinic-statistics__val {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: rgba(0, 0, 0, .87);
      }

      .clinic-statistics__descr {
        font-size: 13px;
        line-height: 15px;
        color: rgba(0, 0, 0, .56);
        text-transform: lowercase;
      }
    }
  }

  .clinic-comment {
    height: 354px;
    overflow-y: auto;
  }

  .clinic-menus {
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    margin-bottom: 1rem;

    a {
      padding: 1rem 1rem;
      flex: 1;
      text-align: center;
      color: black;
      font-size: 15px;
    }

    a.active {
      font-weight: bold;
      background: $blue;
      color: white !important;
    }
  }
}

.clinic-menu-tabs {
  .ant-tabs-nav-list {
    justify-content: space-between;
    width: 100%;
  }

  .ant-tabs-tabpane {
    padding-top: 1rem;
  }

  .ant-tabs-tab-btn {
    text-align: center !important;
  }

  .ant-tabs-tab {
    flex: 1 !important;
    display: block;
  }

  .ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab {
    margin-left: 0px !important;
  }

  .ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab-active {
    border-bottom-color: #f0f0f0;
    border: 1px solid $blue;
  }

  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-operations {
    display: none;
  }
}

.clinicImages {
  height: 400px;

  .slick-slider {
    height: 100%;
  }

  .next-arrow,
  .prev-arrow {
    svg {
      // background-color: rgba(0, 0, 0, 0.2);
      border-radius: 50%;

      // box-shadow: 0px 0px 15px 15px rgba(0, 0, 0, 0.2);
      polygon {
        fill: white;
      }

      width: 50px;
      height: 50px;
    }
  }

  .slick-active {
    div {
      opacity: 1 !important;
    }
  }

  .slick-dots {
    bottom: 10px;

    li {
      width: 12px;
      height: 12px;

      div {
        border: 2px solid $blue,
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .clinic-rating {
    max-width: 320px;
  }
}

@media only screen and (min-width: 768px) {
  .clinic-head__title {
    font-size: 40px;
    line-height: 47px;
  }

  .clinic-statistics {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    .clinic-statistics__item {
      overflow: hidden;
    }
  }
}

.slider-div {
  padding-left: 30px;
  padding-right: 0;
}

@media screen and (max-width: 992px) {
  .slider-div {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .clinicImages {
    height: 300px !important;

    img {
      height: 300px !important;
    }
  }
}

@media screen and (max-width: 576px) {
  .specificClinicComp {
    .clinic-head {
      .clinic-head__title {
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 10px;
        color: rgba(0, 0, 0, .87);
      }
    }

    .clinic-menus {
      a {
        padding: 1rem 1rem;
        flex: 1;
        padding: 10px 0;
      }
    }

    .new-card_about-our-patient__statistic {
      font-size: 15px;

      .material-icons {
        font-size: 20px;
        line-height: 1;
        margin-right: 8px;
      }
    }
  }

  .clinicImages {
    height: 200px !important;

    img {
      height: 200px !important;
    }

    .next-arrow,
    .prev-arrow {
      svg {
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);

        polygon {
          fill: white;
        }

        width: 20px;
        height: 20px;
      }
    }

    .slick-active {
      div {
        opacity: 1 !important;
      }
    }

    .slick-dots {
      bottom: 10px;

      li {
        width: 12px;
        height: 12px;
      }
    }
  }
}